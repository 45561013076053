import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import { useUser } from '../../hooks/useUser'
import { CONTENT_TYPES } from '../../utils/Enum'
import ContentList from '../ContentList'
import { FormContent } from '../CustomForm/CustomFormElements'
import { getUserProfile, hashEmail } from '../GravatarAPI'
import { useModal } from '../Modal/ModalContext'
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements'
import OptionModal from '../OptionModal'
import ScrollToTop from '../ScrollToTop'
import { ContentListSkeleton } from '../Skeleton'

const FollowersList = ({user, type, setArtistsFollowing, userFollowedArtists, setFArtists}) => {
    const [followingTab, setFollowingTab] = useState('users')
    const {goTo} = ScrollToTop()
    const [followers, setFollowers] = useState([])
    const [following, setFollowing] = useState([])
    const [artists, setArtists] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const {currentUser} = useAuth()
    const {modalContent, toggleModal} = useModal()

    const {
        getArtistsUserFollowing, 
        getFollowers, 
        getFollowing,
        isFollowing} = useUser({id: user})
    const navigate  = useNavigate()

    useEffect(() => {
        if(type === 'followers'){
          getUserFollowers()
        }
        if(type === 'following'){
          getUserFollows()
        }
        return () => {}
    }, [type])
    

    const getUserFollowers = async() => {   
      setLoaded(false)     
        return await getFollowers().then(async (fData) => { // Get the users list of followers
          let followersInfo = []
          if( fData?.length > 0)
          {

            let process = []

            for(let i = 0; i <= fData.length - 1; i++){
             
               process.push(isFollowing(fData[i].uid, currentUser?.uid).then((data) => {
                followersInfo.push({
                  ...fData[i],
                  isFollowing: data})
            }).catch(err => {
              //console.log(err)
              return false
            }))
            
            
        } 
        await Promise.all(process)
          setFollowers(followersInfo)
          setLoaded(true)
          return 
        }else{
            setFollowers([])
            setLoaded(true)
            return
        }
          }).catch((err)=>{
            //console.log(err)
            setLoaded(true)     
            return false
          })
      
        
    }

    const getUserFollows= async() => {
      setLoaded(false)
      return await Promise.all([getFollowing(), getArtistsUserFollowing()]).then(async(data) => {
           if(data[0]?.length > 0){
            let followingsInfo = []
            let process = []
            for(let i = 0; i <= data[0]?.length - 1; i++){
              
              process.push(isFollowing(data[0][i].uid, currentUser?.uid)
              .then((data1) => {
                followingsInfo.push({
                  ...data[0][i],
                   isFollowing: data1}) 
                
                return 
              }).catch(err => {
              return false
              }))
            }
            await Promise.all(process)
              setFollowing(followingsInfo)
            }
          if(data[1]?.length > 0){
            setArtists(data[1])
          }
          setLoaded(true)
            return 
          }).catch((err)=>{
            //console.log(err)
            setArtists([])
            setFollowing([])
            setLoaded(true)
            return 
          }) 
    }

   const goToUser = (user) => {
      setLoaded(false)
      toggleModal()
      modalContent()
      goTo(`/u/${user.uid}`)
    }

   const goToArtist = (artist) => {
      setLoaded(false)
      toggleModal()
      modalContent()
      goTo(`/artist/${artist.artistId}`)
    }



    const displayContent = () => {
        switch (type) {
            case 'followers':

                return (
                    <OptionModal heading={'Followers'}>
                       <FormContent style={{maxHeight: '60vh'}}>
                        <ContentList type={CONTENT_TYPES.USER} onButtonClick={goToUser} content={followers}/>
                        </FormContent>
                    </OptionModal>
                )
            case 'following':
              
                return ( <OptionModal heading={'Following'}>
                    <NavContainer><Nav>
                      <NavLink onClick={() => setFollowingTab('users')} active={followingTab === 'users'}>Users</NavLink>
                      <NavLink onClick={() => setFollowingTab('artists')} active={followingTab === 'artists'}>Artists</NavLink>
                      </Nav></NavContainer>
                       <FormContent style={{maxHeight: '60vh'}}>
               {followingTab === 'users' && <ContentList type={CONTENT_TYPES.USER} onButtonClick={goToUser} content={following}/>}
               {followingTab === 'artists' && <ContentList type={CONTENT_TYPES.ARTIST} onButtonClick={goToArtist} content={artists}/>}
               {followingTab === 'labels' && <ContentList type={CONTENT_TYPES.USER} content={following}/>}</FormContent>
                </OptionModal>)
              
                

        
            default:
                return (<OptionModal heading={'Followers'}>
                     <FormContent style={{maxHeight: '60vh'}}>
                        <ContentListSkeleton type={CONTENT_TYPES.USER} content={[1,2,3,4,5]}/>
                    </FormContent>
                </OptionModal>)
        }
    }

return(
    <>
    {!isLoaded && <OptionModal heading={type === 'followers' ? 'Followers' : 'Following'}>
   
                     <FormContent >
                        <ContentListSkeleton type={CONTENT_TYPES.USER}  content={[1,2,3,4,5]}/>
                    </FormContent>
                </OptionModal>}
    {isLoaded && displayContent()}
    
    </>
)

/*     return (
    <>
        <Form>
          
          <FormH1>
              {type === 'followers' && `Followers`}
              {type === 'following' && `Following`}
            </FormH1>
            {type === 'following' && details?.userFollowedArtists &&
            <FormTab>
              <FormTabButton onClick={(e) => {
                setFollowingTab('users')
                e.preventDefault()
                }} isActive={followingTab === 'users'}>Users</FormTabButton>
              <FormTabButton onClick={(e) => {
                setFollowingTab('artists')
                //console.log(details?.userFollowedArtists?.length)
                e.preventDefault()
                }} isActive={followingTab === 'artists'}>Artists</FormTabButton>
            </FormTab>}
          <FormContent >
          {!isLoaded && <><ContentListSkeleton/> <ContentListSkeleton/></>}
          
          
          {isLoaded &&
            
            <>
              {type === 'followers' && details?.userFollowers && 
              <ContentList type={CONTENT_TYPES.USER} onButtonClick={(i) => navigate( `/u/${i?.userId}`)} content={details.userFollowers}/>
              }

              {type === 'followers' && details?.userFollowers?.length === 0 && 
              <FormText style={{color: 'black'}}>No Followers</FormText>
              }
              {type === 'following' && followingTab === 'users' && 
              details.userFollowing?.length === 0 && 
              <FormText style={{color: 'black'}}>Not following anyone</FormText>
              }
              {type === 'following' && followingTab === 'artists' && 
              details?.userFollowedArtists?.length === 0 && 
              <FormText style={{color: 'black'}}>Not following anyone</FormText>
              }

              {type === 'following' && followingTab === 'users' && 
              <ContentList type={CONTENT_TYPES.USER} content={details.userFollowing}/>
              }
              {type === 'following' && followingTab === 'artists' && 
              details?.userFollowedArtists && 
              <ContentList type={CONTENT_TYPES.ARTIST} content={details.userFollowedArtists}/>
              }
          </>}
          </FormContent>
      </Form>
    </>
  )
 */}

export default FollowersList
import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import {
  FaDownload,
  FaPause,
  FaPlay,
  FaRegMoneyBillAlt,
  FaShare,
} from "react-icons/fa";
import { GoReport } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useSonarMusePlayer } from "../../hooks/AudioHook";
import useArtist from "../../hooks/useArtist";
import { useUser } from "../../hooks/useUser";
import {
  ALERT_TYPES,
  ARTIST_SEARCH_TYPES,
  CONTENT_TYPES,
  MINTING,
  MUSIC_PLAYING_FROM,
  MUSIC_PURCHASE_OPTIONS,
  SLIDER_TYPES,
  TRANSACTION_OPTIONS,
} from "../../utils/Enum";
import ImageUtils from "../../utils/ImageUtils";
import {
  convertAlbumsToSonarMuseSchema,
  convertAlbumToSonarMuseSchema,
  convertSongsToSonarMuseSchema,
  getAddressName,
  getIPFSFile,
  getNFTImage,
  getNFTokenFlagType,
} from "../../utils/nfTokensUploads";
import { useAudio } from "../AudioPlayer/context/AudioContext";
import CollectionAudioPlayer from "../CollectionAudioPlayer";
import ContentList from "../ContentList";
import {
  CollectionSongList,
  MusicStoreSongList,
} from "../ContentList/SongList";
import ContentWrapper from "../ContentWrapper";
import {
  FormInput,
  FormLabel,
  FormText,
  FormWrapper,
} from "../CustomForm/CustomFormElements";
import { HoverOption } from "../HoverMenu/HoverMenuElements";
import {MusicOptionsModal } from "../MenuOptions";
import { HoverOption as HoverOption1 } from "../MenuOptions/MenuOptionsElements";
import { useModal } from "../Modal/ModalContext";
import MusicStoreAudioPlayer from "../MusicStore/MusicStorePlayer/MusicStoreAudioPlayer";
import { NavContainer } from "../NavLink/NavLinkElements";
import OptionModal from "../OptionModal";
import PurchaseModal from "../PurchaseModal";
import ScrollToTop from "../ScrollToTop";
import { Slider } from "../Slider";
import { isUserPayingOut } from "../SonarMuseWebMonetization";
import MusicInfo from "./MusicInfo";
import NFTokenInfo from "./MusicInfo/NFTokenInfo";
import {
  AlbumArt,
  AlbumArtContainer,
  AlbumArtWrapper,
  DetailsWrapper,
  Img,
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicDetailsWrapper,
  MusicHeaderWrapper,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageContainer,
  MusicPageWrapper,
  MusicPlayerWrapper,
  MusicPlayPauseButton,
  MusicPriceWrapper,
  MusicSubheading,
  NftBadgeWrapper,
  NftLogo,
  Option,
  OptionButton,
  OptionMenuButton,
  OptionText,
  PurchaseButton,
  Select,
  SongDetailsText,
  SongDetailsWrapper,
  SubHeading,
  Text,
} from "./MusicPageElements";
import useMusic from "../../hooks/useMusic";
import { downloadFiles } from "../../utils/FileUploadsUtils";
import { useAlert } from "../Alert/AlertContext";
import ProgressBar from "../ProgressBar";
import QRCodeForm from "../QRCodeForm";
import { getObjectLength, validateAddress } from "../../utils";
import { ErrorText } from "../ArtistProfile/ArtistContent/ArtistContentElements";
import { Button1, CustomButton } from "../ButtonElement";
import { Theme } from "../WebsiteThemeElements";

const NFTokenPage = ({
  tokenInfo,
  isSongPlaying,
  stopMainPlayer,
  deleteSong,
  hasPurchased,
  currentTab,
  setTab,
  updateWebPageIcon
}) => {
  const navigate = useNavigate();
  const { modalContent, toggleModal, openModal, closeModal } = useModal();
  const { getArtistName, searchArtists } = useArtist({});
  const { getCollectionsByUser } = useUser({});
  const { downloadMusic, getSimilarCollections, getNFTokenCollectionOwners } =
    useMusic({});
  const { addAlert } = useAlert();
  const { goTo } = ScrollToTop();
  const { defaultAlbumArt, NftBadge } = ImageUtils();
  const [songs, setSongs] = useState([]);
  const [showList, setShowList] = useState(false);
  const [nftokenOwners, setNFTokenOwners] = useState([]);
  const [isOwnersLoaded, setOwnersLoaded] = useState(false);
  const [showAllOwners, setShowAll] = useState(false);
  const { currentUser } = useAuth();
  
  const [recommendations, setRecommendations] = useState([]);
  const [isRecommendLoaded, setRecommendLoaded] = useState(false);
  const [isArtistCollectionLoaded, setArtistCollectionLoaded] = useState(false);
  const [artistCollections, setArtistCollections] = useState([]);
  const [artistName, setArtistName] = useState(
    tokenInfo?.nftoken?.issuer ? tokenInfo?.nftoken?.issuer : ""
  );
  const [artistId, setArtistId] = useState("");
  const [tab, setNftTab] = useState(
    tokenInfo?.collection &&
      !tokenInfo.nftoken?.is_burned &&
      (tokenInfo?.collection?.issuer === currentUser?.uid ||
        tokenInfo?.nftoken?.owner === currentUser?.uid)
      ? currentTab
      : "details"
  );
  const {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    setTimeJump,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    setCurrentSong,
    timeTravel,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    closeViewer,
    isMute,
    openViewer,
  } = useSonarMusePlayer();

  const value = {
    timeJump,
    currentSong,
    isPlaying,
    duration,
    currentTime,
    progressBar,
    audioPlayer,
    changeRange,
    stopMainPlayer,
    setTimeJump,
    volume,
    isRepeat,
    calcTime,
    backThirty,
    forwardThirty,
    isPodcast,
    isShuffle,
    nextSong,
    handleToggle,
    prevSong,
    playlist,
    setPlaylist,
    togglePlayPause,
    toggleMute,
    toggleVolume,
    isOpen,
    closeViewer,
    isMute,
    openViewer,
  };
  useEffect(() => {
    if (isSongPlaying) {
      if (isPlaying) {
        handleToggle("play-pause", false);
        timeTravel(0);
      }
    }
    return () => {};
  }, [isSongPlaying, isPlaying]);

  useEffect(() => {
    updateWebPageIcon(tokenInfo?.collection
      ? tokenInfo?.collection?.cover?.url
      : tokenInfo?.nftoken?.metadata?.image
      ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
      : defaultAlbumArt)
    return () => {}
  },[])

  useEffect(() => {
    getSongs();
    return () => {};
  }, [songs?.length === 0]);

  useEffect(() => {
    displayArtistName();
    if(tokenInfo?.nftoken?.metadata?.mintType){
    getNFTokenCollectionOwners(
      tokenInfo?.nftoken?.issuer,
      tokenInfo?.nftoken?.nft_taxon
    )
      .then((owners) => {
        setNFTokenOwners(owners);
        setOwnersLoaded(true);
      })
      .catch((err) => {
        setOwnersLoaded(true);
      });}
    return () => {};
  }, [tokenInfo?.nftoken?.issuer]);

  useEffect(() => {
    if (tokenInfo?.nftoken?.metadata?.mintType) {
      getSimilarCollections(tokenInfo?.nftoken?.metadata?.mintType, 0)
        .then((collections) => {
          setRecommendations(collections);
          setRecommendLoaded(true);
        })
        .catch((err) => {
          setRecommendLoaded(true);
        });
      getCollectionsByUser(tokenInfo?.nftoken?.issuer, 0)
        .then((cols) => {
          setArtistCollections(cols);
          setArtistCollectionLoaded(true);
        })
        .catch((err) => {
          setArtistCollectionLoaded(true);
        });
    }
  }, [tokenInfo?.nftoken?.metadata?.mintType]);

  const downloadNFTokenMusic = async () => {
    addAlert({
      title: "Starting Download",
      type: ALERT_TYPES.INFO,
      message: `Processing download. Please wait.`,
    });
    let content = await downloadMusic(
      currentUser?.uid,
      CONTENT_TYPES.NFTOKEN,
      tokenInfo?.nftoken?.nft_id
    );
    await downloadFiles(content);
    addAlert({
      title: "Album Download",
      type: ALERT_TYPES.SUCCESS,
      message: `Downloading Album`,
    });
  };

  const displayArtistName = async () => {
    return searchArtists(
      ARTIST_SEARCH_TYPES.ADDRESS,
      tokenInfo?.nftoken?.issuer
    )
      .then(async (artist) => {
        if (artist) {
          setArtistName(artist?.artistName);
          /* setProfileImg(artist?.artistSettings?.profilePictureType === "custom" && artist?.profilePicture ?
        artist?.profilePicture :
        artist?.photoUrl
          ? artist.photoUrl + "?s=600&d=mp"
          : DEFAULT_IMAGE(600)) */
          setArtistId(artist?.artistId);
          return;
        } else {
          let issuerName = await getAddressName(tokenInfo?.nftoken?.issuer);
          if (getObjectLength(issuerName) > 0) {
            setArtistName(issuerName?.name);
            //setProfileImg(issuerName?.profileUrl + "?s=600&d=mp")
            return;
          }
          setArtistName(issuerName);
          return;
        }
      })
      .catch((err) => {
        setArtistName(tokenInfo?.nftoken?.issuer);
        //console.error(err)
      });
  };

  const getSongs = () => {
    let s = [];
    if (tokenInfo?.nftoken?.metadata?.type?.includes("music")) {
      switch (tokenInfo?.nftoken?.metadata?.mintType) {
        case MINTING.TYPE.SINGLE:
          s = convertSongsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.songs,
            tokenInfo?.nftoken?.metadata?.image
          )?.map((s) => {
            return {
              ...s,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.NFT,
                id: tokenInfo?.nftoken?.nft_id,
              },
            };
          });
          setSongs(s);
          setPlaylist(s);
          setCurrentSong(0);
          break;
        case MINTING.TYPE.ALBUM:
          s = convertAlbumsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.albums,
            tokenInfo?.nftoken?.metadata?.image
          )[0]?.songs.map((song) => {
            return {
              ...song,
              playingFrom: {
                playingFrom: MUSIC_PLAYING_FROM.NFT,
                id: tokenInfo?.nftoken?.nft_id,
              },
            };
          });
          setSongs(s);
          setPlaylist(s);
          setCurrentSong(0);
          break;
        case MINTING.TYPE.CUSTOM:
          if (
            tokenInfo?.nftoken?.metadata?.songs?.length > 0 &&
            !tokenInfo?.nftoken?.metadata?.albums
          ) {
            setSongs(
              convertSongsToSonarMuseSchema(
                tokenInfo?.nftoken?.metadata?.songs,
                tokenInfo?.nftoken?.metadata?.image
              )?.map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: tokenInfo?.nftoken?.nft_id,
                  },
                };
              })
            );
            setPlaylist(
              convertSongsToSonarMuseSchema(
                tokenInfo?.nftoken?.metadata?.songs,
                tokenInfo?.nftoken?.metadata?.image
              )?.map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: tokenInfo?.nftoken?.nft_id,
                  },
                };
              })
            );
            setCurrentSong(0);
          }
          if (
            tokenInfo?.nftoken?.metadata?.songs.length > 0 &&
            tokenInfo?.nftoken?.metadata?.albums?.length > 0
          ) {
            setSongs(
              convertSongsToSonarMuseSchema(
                tokenInfo?.nftoken?.metadata?.songs,
                tokenInfo?.nftoken?.metadata?.image
              )?.map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: tokenInfo?.nftoken?.nft_id,
                  },
                };
              })
            );
            setPlaylist(
              convertSongsToSonarMuseSchema(
                tokenInfo?.nftoken?.metadata?.songs,
                tokenInfo?.nftoken?.metadata?.image
              )?.map((s) => {
                return {
                  ...s,
                  playingFrom: {
                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                    id: tokenInfo?.nftoken?.nft_id,
                  },
                };
              })
            );
            setCurrentSong(0);
          }
          if (
            !tokenInfo?.nftoken?.metadata?.songs &&
            tokenInfo?.nftoken?.metadata?.albums?.length > 0
          ) {
            s = convertAlbumsToSonarMuseSchema(
              tokenInfo?.nftoken?.metadata?.albums,
              tokenInfo?.nftoken?.metadata?.image
            )[0]?.songs.map((song) => {
              return {
                ...song,
                playingFrom: {
                  playingFrom: MUSIC_PLAYING_FROM.NFT,
                  id: tokenInfo?.nftoken?.nft_id,
                },
              };
            });
            setSongs(s);
            setPlaylist(s);
            setCurrentSong(0);
          }
          break;

        default:
          break;
      }
    }
  };

  const playSongs = (songs) => {
    /* let songs =
      tokenInfo?.nftoken?.metadata?.songs?.length > 0
        ? convertSongsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.songs).map((s) => {
          return {...s,
          playingFrom:{
            playingFrom: MUSIC_PLAYING_FROM.NFT,
            id: tokenInfo?.nftoken?.nft_id,
          }}
        })
        : [];
    let albums =
      tokenInfo?.nftoken?.metadata?.albums?.length > 0
        ? convertAlbumsToSonarMuseSchema(
            tokenInfo?.nftoken?.metadata?.albums
          ).map(a => {
            songs.push(...a.songs.map(s => {
              return {...s,
                playingFrom:{
                  playingFrom: MUSIC_PLAYING_FROM.NFT,
                  id: tokenInfo?.nftoken?.nft_id,
                }}
            }))
            return a
          })
        : []; */
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.nft_id,
    };
    setPlaylist(songs);
    stopMainPlayer();
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    if (isPlaying) {
      handleToggle("play-pause", false);
    } else {
      setCurrentSong(0);
      handleToggle("play-pause", true);
    }
  };

  const playMusic = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.nft_id,
    };
    if (isPlaying) {
      togglePlay();
      return;
    }
    if (tokenInfo?.nftoken?.metadata?.mintType !== MINTING.TYPE.CUSTOM) {
      togglePlay();
      return;
    } else {
      openModal();
      modalContent(
        <>
          <OptionModal heading={"Select Music to Play from NFT"}>
            {tokenInfo?.nftoken?.metadata?.songs?.length > 0 &&
              tokenInfo?.nftoken?.metadata?.albums?.length > 0 && (
                <>
                  {" "}
                  <HoverOption
                    onClick={() => {
                      let s = convertSongsToSonarMuseSchema(
                        tokenInfo?.nftoken?.metadata?.songs,
                        tokenInfo?.nftoken?.metadata?.image
                      ).map((song) => {
                        return {
                          ...song,
                          playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.NFT,
                            id: tokenInfo?.nftoken?.nft_id,
                          },
                        };
                      });
                      localStorage.setItem(
                        "isPlayingFrom",
                        JSON.stringify(playingFrom)
                      );
                      setSongs(s);
                      setPlaylist(s);
                      currentSong(0);
                      handleToggle("play");
                      modalContent();
                      closeModal();
                    }}
                  >
                    Play Songs
                  </HoverOption>
                  <HoverOption
                    onClick={() => {
                      albumSelector(tokenInfo?.nftoken?.metadata?.albums);
                    }}
                  >
                    Play Albums
                  </HoverOption>
                  <HoverOption
                    onClick={() => {
                      playAllMusic();
                      modalContent();
                      closeModal();
                    }}
                  >
                    Play All Music
                  </HoverOption>
                  <HoverOption
                    onClick={() => {
                      closeModal();
                      modalContent();
                    }}
                  >
                    Cancel
                  </HoverOption>
                </>
              )}
            {tokenInfo?.nftoken?.metadata?.albums?.length > 1 &&
              albumSelector(tokenInfo?.nftoken?.metadata?.albums)}
          </OptionModal>
        </>
      );
    }
  };

  const playAlbum = (album) => {
    let s = [];
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.nft_id,
    };
    album.songs.map((song) => {
      s.push(song);
      return s;
    });
    setSongs(s);
    setPlaylist(s);
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    handleToggle("play");
    setCurrentSong(0);
  };

  const playAllMusic = () => {
    let allMusic = [];
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.nft_id,
    };
    let s = convertSongsToSonarMuseSchema(
      tokenInfo?.nftoken?.metadata?.songs,
      tokenInfo?.nftoken?.metadata?.image
    ).map((song) => {
      return {
        ...song,
        playingFrom: playingFrom,
      };
    });
    allMusic.push(...s);
    convertAlbumsToSonarMuseSchema(
      tokenInfo?.nftoken?.metadata?.albums,
      tokenInfo?.nftoken?.metadata?.image
    ).map((album) => {
      return album.songs.map((song) => {
        allMusic.push({
          ...song,
          playingFrom: playingFrom,
        });
        return song;
      });
    });
    setSongs(allMusic);
    setPlaylist(allMusic);
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    setCurrentSong(0);
    handleToggle("play");
    closeModal();
  };

  const togglePlay = () => {
    let playingFrom = {
      playingFrom: MUSIC_PLAYING_FROM.NFT,
      id: tokenInfo?.nftoken?.nft_id,
    };
    stopMainPlayer();
    localStorage.setItem("isPlayingFrom", JSON.stringify(playingFrom));
    if (isPlaying && isPlayingFrom()) {
      // if(isPlaying && subscribed to coil)
      handleToggle("play-pause", false);
    } else {
      handleToggle("play");
      setCurrentSong(0);
    }
  };

  const isPlayingFrom = () => {
    if (
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.playingFrom ===
        MUSIC_PLAYING_FROM.NFT &&
      JSON.parse(localStorage.getItem("isPlayingFrom"))?.id ===
        tokenInfo?.nftoken?.nft_id
    ) {
      return true;
    }
    return false;
  };

  const albumSelector = (albums) => {
    modalContent(
      <OptionModal>
        {albums.map((a) => {
          return (
            <HoverOption
              onClick={() => {
                let s = convertAlbumToSonarMuseSchema(
                  a,
                  tokenInfo?.nftoken?.metadata?.image
                )?.songs.map((song) => {
                  return {
                    ...song,
                    playingFrom: {
                      playingFrom: MUSIC_PLAYING_FROM.NFT,
                      id: tokenInfo?.nftoken?.nft_id,
                    },
                  };
                });
                setSongs(s);
                setPlaylist(s);
                setCurrentSong(0);
                modalContent();
                closeModal();
              }}
            >
              Play {a.albumName}
            </HoverOption>
          );
        })}
      </OptionModal>
    );
  };

  const burnToken = () => {
    openModal()
    modalContent(<OptionModal heading={"Are you sure you want to continue with burning this NFToken?"} additionalText={"Burning this NFT means that you can never get it back, it is gone forever"}>
<HoverOption1 onClick={confirmTokenBurn}>Continue</HoverOption1>
<CustomButton onClick={() => {
  closeModal() 
  modalContent()}}>Cancel</CustomButton>
    </OptionModal>)

  }

  const confirmTokenBurn = () => {
    openModal();
    modalContent(
      <QRCodeForm
        type={TRANSACTION_OPTIONS.NFTOKEN_BURN}
        heading={`Burning ${
          tokenInfo?.nftoken?.metadata
            ? tokenInfo?.nftoken?.metadata?.name
              ? tokenInfo?.nftoken?.metadata?.name
              : tokenInfo?.nftoken?.metadata?.collection
              ? tokenInfo?.nftoken?.metadata?.collection?.name
              : `${tokenInfo?.nftoken?.nft_id?.substring(
                  0,
                  5
                )}...${tokenInfo?.nftoken?.nft_id?.substring(
                  tokenInfo?.nftoken?.nft_id?.length - 5,
                  tokenInfo?.nftoken?.nft_id?.length - 1
                )}`
            : `${tokenInfo?.nftoken?.nft_id?.substring(
                0,
                5
              )}...${tokenInfo?.nftoken?.nft_id?.substring(
                tokenInfo?.nftoken?.nft_id?.length - 5,
                tokenInfo?.nftoken?.nft_id?.length - 1
              )}`
        }
`}
        body={{ sender: currentUser?.uid, tokenId: tokenInfo?.nftoken?.nft_id }}
        cancelQr={() => {
          toggleModal();
          modalContent();
        }}
        onFail={() => {
          toggleModal();
          modalContent(
            <OptionModal>
              <HoverOption
                onClick={() => {
                  burnToken();
                }}
              >
                Try Again
              </HoverOption>
              <HoverOption
                onClick={() => {
                  closeModal();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        }}
        onSuccess={() => {
          goTo("/home");
          modalContent(
            <OptionModal heading={`${
              tokenInfo?.nftoken?.metadata
                ? tokenInfo?.nftoken?.metadata?.name
                  ? tokenInfo?.nftoken?.metadata?.name
                  : tokenInfo?.nftoken?.metadata?.collection
                  ? tokenInfo?.nftoken?.metadata?.collection?.name
                  : `${tokenInfo?.nftoken?.nft_id?.substring(
                      0,
                      5
                    )}...${tokenInfo?.nftoken?.nft_id?.substring(
                      tokenInfo?.nftoken?.nft_id?.length - 5,
                      tokenInfo?.nftoken?.nft_id?.length - 1
                    )}`
                : `${tokenInfo?.nftoken?.nft_id?.substring(
                    0,
                    5
                  )}...${tokenInfo?.nftoken?.nft_id?.substring(
                    tokenInfo?.nftoken?.nft_id?.length - 5,
                    tokenInfo?.nftoken?.nft_id?.length - 1
                  )}`
            } burned successfully `}>
              <HoverOption
                onClick={() => {
                  closeModal();
                }}
              >
                OK
              </HoverOption>
            </OptionModal>
          );
        }}
      />
    );
  };

  /* const getSongs = () => {
    let albums = [];
    if (tokenInfo?.nftoken?.metadata?.albums?.length > 0) {
      convertAlbumsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.albums)
        ?.filter((a) => a?.songs?.length > 0)
        .map((a) => {
          return albums.push(...a?.songs);
        });
    }

    let songs = tokenInfo?.nftoken?.metadata?.songs
      ? convertSongsToSonarMuseSchema(tokenInfo?.nftoken?.metadata?.songs)?.map(
          (s) => s
        )
      : [];
    let music = [...albums, ...songs];
    //console.log(music);
    return music.map((s) => {
      return {
        ...s,
        playingFrom: {
          playingFrom: MUSIC_PLAYING_FROM.NFT,
          id: tokenInfo?.nftoken?.nft_id,
        },
      };
    });
  };
 */
  const playSong = (i) => {
    //console.log(i);
    setCurrentSong(i);
    handleToggle("play");
  };

  const displayNFTokenFlag = (flag) => {
    switch (flag) {
      case 8:
        return (
          <>
            {` ${MINTING.FLAG.TRANSFERABLE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.TRANSFERABLE}
              </div>
            </FormText>
          </>
        );
      case 9:
        return (
          <>
            {` ${MINTING.FLAG.BURNABLE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.BURNABLE}
              </div>
            </FormText>
          </>
        );

      case 10:
        return (
          <>
            {` ${MINTING.FLAG.ONLY_XRP}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.ONLY_XRP}
              </div>
            </FormText>
          </>
        );

      case 4:
        return (
          <>
            {` ${MINTING.FLAG.TRUSTLINE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.TRUSTLINE}
              </div>
            </FormText>
          </>
        );

      default:
        return (
          <>
            {` ${MINTING.FLAG.TRANSFERABLE}`}
            <FormText>
              <div style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
                {MINTING.FLAG_DESCRIPTION.TRANSFERABLE}
              </div>
            </FormText>
          </>
        );
    }
  };

  return (
    <>
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
            src={
              tokenInfo?.collection
                ? tokenInfo?.collection?.cover?.url
                : tokenInfo?.nftoken?.metadata?.image
                ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
                : defaultAlbumArt
            }
            color={
              tokenInfo?.nftoken?.is_burned
                ? "red"
                : tokenInfo?.nftoken?.metadata?.color
            }
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage
               onContextMenu={(e) => {
                e.preventDefault();
              }}
                src={
                  tokenInfo?.nftoken?.metadata?.image
                    ? getNFTImage(tokenInfo?.nftoken?.metadata?.image)
                    : defaultAlbumArt
                }
                color={
                  tokenInfo?.nftoken?.is_burned
                    ? "red"
                    : tokenInfo?.nftoken?.metadata?.color
                }
              />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
              <SubHeading>
                <small>
                  NFToken
                  {tokenInfo?.nftoken?.metadata?.mintType
                    ? `: ${tokenInfo?.nftoken?.metadata?.mintType}`
                    : ""}
                </small>
                <NftBadgeWrapper>
                  <NftLogo src={NftBadge} alt={"NFT"} />
                </NftBadgeWrapper>
              </SubHeading>
              <MusicHeading
                style={{ color: tokenInfo?.nftoken?.is_burned && "red" }}
              >
                {`${
                  tokenInfo.collection?.name
                    ? tokenInfo.collection?.name
                    : tokenInfo?.nftoken?.metadata?.name
                    ? tokenInfo?.nftoken?.metadata?.name
                    : tokenInfo?.nftoken?.metadata?.collection?.name
                    ? tokenInfo?.nftoken?.metadata?.collection?.name
                    : tokenInfo?.nftoken?.nft_id
                } ${
                  tokenInfo?.nftoken?.owner === currentUser?.uid
                    ? `[You Own This]`
                    : ""
                } `}{" "}
                <MusicHeadingSmall>
                  {tokenInfo?.nftoken?.is_burned && ` (Token Burned)`}
                </MusicHeadingSmall>
              </MusicHeading>
              <MusicSubheading>
                <MusicHeading
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    artistId?.trim()?.length > 0
                      ? navigate(`/artist/${artistId}`)
                      : navigate(`/u/${tokenInfo?.nftoken?.issuer}`)
                  }
                >
                  {artistName}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>
              {`${tokenInfo?.nftoken?.metadata?.type
                ? tokenInfo?.nftoken?.metadata?.type
                : tokenInfo?.nftoken?.metadata?.nftType
                ? tokenInfo?.nftoken?.metadata?.nftType
                : "N/A"} · ${getNFTokenFlagType(tokenInfo?.nftoken?.flags)}`}
              </MusicHeadingSmall>
              {!tokenInfo?.nftoken?.is_burned && (
                <>
                  <MusicOptionsContainer>
                    {(tokenInfo?.nftoken?.metadata?.albums?.length > 0 ||
                      tokenInfo?.nftoken?.metadata?.songs?.length > 0 ||
                      tokenInfo?.collection) &&
                      currentUser?.uid === tokenInfo?.nftoken?.owner &&
                      !tokenInfo?.nftoken?.is_burned && (
                        <>
                          <MusicPlayPauseButton
                            onClick={() => {
                              playMusic();
                            }}
                          >
                            {isPlaying ? (
                              <>
                                <FaPause /> Pause
                              </>
                            ) : (
                              <>
                                <FaPlay
                                  style={{ position: "relative", left: "1%" }}
                                />{" "}
                                Play
                              </>
                            )}
                          </MusicPlayPauseButton>
                        </>
                      )}

                    {tokenInfo?.nftoken?.owner === currentUser?.uid &&
                      tokenInfo?.nftoken?.metadata?.mintType && (
                        <MusicPlayPauseButton
                          transparent={true}
                          onClick={() => {
                            downloadNFTokenMusic();
                            closeModal();
                            modalContent();
                          }}
                        >
                          <FaDownload
                            style={{ position: "relative", left: "1%" }}
                          />{" "}
                          Download
                        </MusicPlayPauseButton>
                      )}

                    {tokenInfo?.collection && currentUser?.uid && (
                      <OptionButton
                      onClick={() =>
                        {openModal()
                        modalContent(<MusicOptionsModal content={tokenInfo?.collection} type={ CONTENT_TYPES.COLLECTION}/>)
                      }
                    }
                      >
                        <OptionMenuButton />
                      </OptionButton>
                    )}
                  </MusicOptionsContainer>
                  <MusicOptionsContainer>
                    {tokenInfo?.nftoken?.owner === currentUser?.uid && (
                      <MusicPlayPauseButton
                        transparent={true}
                        onClick={() => burnToken()}
                      >
                        Burn
                      </MusicPlayPauseButton>
                    )}
                    {tokenInfo?.nftoken?.owner === currentUser?.uid && (
                      <MusicPlayPauseButton
                        onClick={() => {
                          openModal();
                          modalContent(<NFTokenTransfer token={tokenInfo} />);
                        }}
                      >
                        Transfer
                      </MusicPlayPauseButton>
                    )}
                  </MusicOptionsContainer>
                </>
              )}
              {tokenInfo?.nftoken?.metadata?.description && (
                <MusicOptionsContainer>
                  <MusicAboutWrapper>
                    {tokenInfo?.nftoken?.metadata?.description}
                  </MusicAboutWrapper>
                </MusicOptionsContainer>
              )}
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
       
        {tokenInfo?.collection?.tokenSupply &&
          tokenInfo?.collection?.amountSold > 0 && (
            <>
              <MusicInfoWrapper>
                  <ProgressBar
                    message={`${parseFloat(
                      (tokenInfo?.collection?.amountSold / tokenInfo?.collection?.tokenSupply) * 100
                    )}% of ${tokenInfo?.collection?.tokenSupply} NFTs Sold`}
                    width={
                      (tokenInfo?.collection?.amountSold / tokenInfo?.collection?.tokenSupply) * 100
                    }
                    color={
                      tokenInfo?.collection?.color ? tokenInfo?.collection?.color : Theme({}).successColor
                    }
                  />
                </MusicInfoWrapper>
            </>
          )}
        
        <MusicInfoWrapper>
          {(tokenInfo?.nftoken?.metadata?.albums?.length > 0 ||
            tokenInfo?.nftoken?.metadata?.songs?.length > 0 ||
            tokenInfo?.collection) &&
            !tokenInfo?.nftoken?.is_burned &&
            (currentUser?.uid === tokenInfo?.nftoken?.owner ||
              tokenInfo?.collection?.issuer === currentUser?.uid) && (
              <>
                <div style={{ padding: "0 4%" }}>
                  <CollectionAudioPlayer {...value} />
                </div>
              </>
            )}
        </MusicInfoWrapper>
        <NavContainer>
          <MusicNav>
            {(tokenInfo?.collection ||
              tokenInfo?.nftoken?.metadata?.songs?.length > 0 ||
              tokenInfo?.nftoken?.metadata?.albums?.length > 0) &&
              !tokenInfo?.nftoken?.is_burned &&
              (tokenInfo?.collection?.issuer === currentUser?.uid ||
                tokenInfo?.nftoken?.owner === currentUser?.uid) && (
                <MusicNavLink
                  onClick={() => {
                    setTab("music");
                    setNftTab("music");
                  }}
                  active={tab === "music"}
                >
                  Music
                </MusicNavLink>
              )}

            <MusicNavLink
              onClick={() => {
                setTab("details");
                setNftTab("details");
              }}
              active={tab === "details"}
            >
              NFToken Details
            </MusicNavLink>

            {tokenInfo?.collection && (
              <MusicNavLink
                onClick={() => {
                  setTab("recommendations");
                  setNftTab("recommendations");
                }}
                active={tab === "recommendations"}
              >
                Recommendations
              </MusicNavLink>
            )}
          </MusicNav>
        </NavContainer>
        {tab === "music" &&
          (tokenInfo?.collection?.issuer === currentUser?.uid ||
            tokenInfo?.nftoken?.owner === currentUser?.uid) && (
            <MusicContentWrapper>
              {songs?.length > 0 && (
                <ContentWrapper heading={"Current NFT Playlist"}>
                  <CollectionSongList
                    onButtonClick={playSong}
                    type={CONTENT_TYPES.SONG}
                    content={songs}
                    isPlaying={isPlaying}
                    currentSong={currentSong}
                    playlist={playlist}
                  />
                </ContentWrapper>
              )}
              {tokenInfo?.nftoken?.metadata?.mintType && (
                <ContentWrapper
                  heading={`Available Music from NFToken ${
                    tokenInfo?.nftoken?.metadata?.name
                      ? tokenInfo?.nftoken?.metadata?.name
                      : tokenInfo?.nftoken?.metadata?.collection?.name
                      ? tokenInfo?.nftoken?.metadata?.collection?.name
                      : tokenInfo?.nftoken?.nft_id
                  }`}
                  onClick={() => setShowList(!showList)}
                  subheading={showList ? "Hide" : "Show"}
                >
                  {showList && (
                    <>
                      {tokenInfo?.nftoken?.metadata?.albums?.length > 0 && (
                        <FormWrapper>
                          <ContentWrapper heading={`Albums`}>
                            {convertAlbumsToSonarMuseSchema(
                              tokenInfo?.nftoken?.metadata?.albums,
                              tokenInfo?.nftoken?.metadata?.image
                            ).map((album, index) => {
                              return (
                                <ContentWrapper
                                  heading={album?.albumName}
                                  subheading={"Play"}
                                  onClick={() => playAlbum(album)}
                                >
                                  <MusicStoreSongList
                                    isPlaying={isPlaying}
                                    currentSong={currentSong}
                                    playlist={playlist}
                                    onButtonClick={playSong}
                                    type={CONTENT_TYPES.SONG}
                                    content={album?.songs?.map((s) => {
                                      return {
                                        ...s,
                                        playingFrom: {
                                          playingFrom: MUSIC_PLAYING_FROM.NFT,
                                          id: tokenInfo?.nftoken?.nft_id,
                                        },
                                      };
                                    })}
                                  />
                                </ContentWrapper>
                              );
                            })}
                          </ContentWrapper>
                        </FormWrapper>
                      )}

                      {tokenInfo?.nftoken?.metadata?.songs?.length > 0 && (
                        <FormWrapper>
                          <ContentWrapper
                            heading={"Available Songs"}
                            subheading={"Play"}
                            onClick={() =>
                              playSongs(
                                convertSongsToSonarMuseSchema(
                                  tokenInfo?.nftoken?.metadata?.songs,
                                  tokenInfo?.nftoken?.metadata?.image
                                ).map((s) => {
                                  return {
                                    ...s,
                                    playingFrom: {
                                      playingFrom: MUSIC_PLAYING_FROM.NFT,
                                      id: tokenInfo?.nftoken.nft_id,
                                    },
                                  };
                                })
                              )
                            }
                          >
                            <MusicStoreSongList
                              onButtonClick={playSong}
                              type={CONTENT_TYPES.SONG}
                              content={convertSongsToSonarMuseSchema(
                                tokenInfo?.nftoken?.metadata?.songs,
                                tokenInfo?.nftoken?.metadata?.image
                              ).map((s) => {
                                return {
                                  ...s,
                                  playingFrom: {
                                    playingFrom: MUSIC_PLAYING_FROM.NFT,
                                    id: tokenInfo?.nftoken.nft_id,
                                  },
                                };
                              })}
                              isPlaying={isPlaying}
                              currentSong={currentSong}
                              playlist={playlist}
                            />
                          </ContentWrapper>
                        </FormWrapper>
                      )}
                    </>
                  )}
                </ContentWrapper>
              )}
            </MusicContentWrapper>
          )}

        {tab === "recommendations" && (
          <>
            <MusicContentWrapper>
              <ContentWrapper heading={`Collections by ${artistName}`}
              onClick={() => artistCollections?.length > 10 &&
                navigate(`/artist/${tokenInfo?.nftoken?.issuer}/collections`)
              } subheading={artistCollections?.length > 10 && 'View All'}
              >
                {isArtistCollectionLoaded &&
                  artistCollections?.collections.filter(
                    (c) =>
                      c.collectionId !== tokenInfo?.collection?.collectionId
                  )?.length > 0 && (
                    <Slider
                      type={SLIDER_TYPES.COLLECTION}
                      id={"trending collections"}
                      slides={artistCollections?.collections.filter(
                        (c) =>
                          c.collectionId !== tokenInfo?.collection?.collectionId
                      )}
                    />
                  )}
                {!isArtistCollectionLoaded &&
                  artistCollections?.length === 0 && (
                    <Slider
                      type={SLIDER_TYPES.PURCHASE_SKELETON}
                      id={"trending collections"}
                    />
                  )}
              </ContentWrapper>
            </MusicContentWrapper>

            {tokenInfo?.nftoken?.metadata?.mintType && (
              <MusicContentWrapper>
                <ContentWrapper
                  heading={`Other ${tokenInfo?.nftoken?.metadata?.mintType} Music NFT Collections`}
                  onClick={() => recommendations?.length > 10 &&
                    navigate(`/collection/${tokenInfo?.nftoken?.metadata?.mintType}/similarCollections`)
                  } subheading={recommendations?.length > 10 && 'View All'}
                >
                  {isRecommendLoaded &&
                    recommendations?.length > 0 && (
                      <Slider
                        type={SLIDER_TYPES.COLLECTION}
                        id={"similar collections"}
                        slides={recommendations.collections.filter(
                          (c) =>
                            c.collectionId !==
                            tokenInfo?.collection?.collectionId
                        )}
                      />
                    )}
                  {!isRecommendLoaded && recommendations?.length === 0 && (
                    <Slider
                      type={SLIDER_TYPES.PURCHASE_SKELETON}
                      id={"similar collections"}
                    />
                  )}
                </ContentWrapper>
              </MusicContentWrapper>
            )}
          </>
        )}

        {tab === "details" && (
          <>
            {" "}
            <MusicInfo
              type={CONTENT_TYPES.NFTOKEN}
              content={tokenInfo?.nftoken}
            />
            {tokenInfo?.nftoken?.metadata?.mintType && (
              <ContentWrapper
                heading={`Available Music from NFToken ${
                  tokenInfo?.nftoken?.metadata?.name
                    ? tokenInfo?.nftoken?.metadata?.name
                    : tokenInfo?.nftoken?.metadata?.collection?.name
                    ? tokenInfo?.nftoken?.metadata?.collection?.name
                    : tokenInfo?.nftoken?.nft_id
                }`}
              >
                {tokenInfo?.nftoken?.metadata?.albums?.length > 0 && (
                  <ContentWrapper heading={`Albums`}>
                    {convertAlbumsToSonarMuseSchema(
                      tokenInfo?.nftoken?.metadata?.albums,
                      tokenInfo?.nftoken?.metadata?.image
                    ).map((album, index) => {
                      return (
                        <ContentWrapper
                          heading={album?.albumName}
                          subheading={"Play"}
                          onClick={() => playAlbum(album)}
                        >
                          <MusicStoreSongList
                            isPlaying={isPlaying}
                            currentSong={currentSong}
                            playlist={playlist}
                            onButtonClick={playSong}
                            type={CONTENT_TYPES.SONG}
                            content={album?.songs?.map((s) => {
                              return {
                                ...s,
                                playingFrom: {
                                  playingFrom: MUSIC_PLAYING_FROM.NFT,
                                  id: tokenInfo?.nftoken?.nft_id,
                                },
                              };
                            })}
                          />
                        </ContentWrapper>
                      );
                    })}
                  </ContentWrapper>
                )}

                {tokenInfo?.nftoken?.metadata?.songs?.length > 0 && (
                  <ContentWrapper
                    heading={"Available Songs"}
                    onClick={() =>
                      playSongs(
                        convertSongsToSonarMuseSchema(
                          tokenInfo?.nftoken?.metadata?.songs
                        ).map((s) => {
                          return {
                            ...s,
                            playingFrom: {
                              playingFrom: MUSIC_PLAYING_FROM.NFT,
                              id: tokenInfo?.nftoken.nft_id,
                            },
                          };
                        })
                      )
                    }
                  >
                    <MusicStoreSongList
                      onButtonClick={playSong}
                      type={CONTENT_TYPES.SONG}
                      content={convertSongsToSonarMuseSchema(
                        tokenInfo?.nftoken?.metadata?.songs
                      ).map((s) => {
                        return {
                          ...s,
                          playingFrom: {
                            playingFrom: MUSIC_PLAYING_FROM.NFT,
                            id: tokenInfo?.nftoken.nft_id,
                          },
                        };
                      })}
                      isPlaying={isPlaying}
                      currentSong={currentSong}
                      playlist={playlist}
                    />
                  </ContentWrapper>
                )}
              </ContentWrapper>
            )}
          </>
        )}
        {tokenInfo?.nftoken?.metadata?.mintType && (
          <>
          {isOwnersLoaded && nftokenOwners?.length > 0 && (<ContentWrapper
            heading={`${tokenInfo.collection?.name} NFToken Owners (${nftokenOwners?.length})`}
            onClick={() =>
              nftokenOwners.length > 10 && setShowAll(!showAllOwners)
            }
            subheading={showAllOwners ? "Hide" : "View All"}
          >
            
              <Slider
                heightScroll={showAllOwners}
                slides={nftokenOwners}
                type={SLIDER_TYPES.USER}
                id={"nftoken owners"}
              /></ContentWrapper>
            )}
            {!isOwnersLoaded && (
              <ContentWrapper
              heading={`${tokenInfo.collection?.name} NFToken Owners`}>
              <Slider
                type={SLIDER_TYPES.USER_SKELETON}
                id={"nftoken owners"}
              /></ContentWrapper>
            )}
          </>
        )}
      </MusicPageWrapper>
    </>
  );
};

function NFTokenTransfer({ token }) {
  const { openModal, modalContent, closeModal } = useModal();
  const [destination, setDestination] = useState("");
  const [error, setError] = useState();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const confirmTransfer = () => {
    openModal();
    modalContent(
      <QRCodeForm
        type={TRANSACTION_OPTIONS.NFTOKEN_TRANSFER}
        body={{
          sender: currentUser?.uid,
          destination: destination.trim(),
          tokenId: token?.nftoken?.nft_id,
        }}
        onSuccess={() => {
          closeModal();
          //navigate(`?refresh=true`)
        }}
        onFail={() => {
          closeModal();
        }}
        cancelQr={() => {
          closeModal();
        }}
        heading={`Transferring ${
          token?.nftoken?.metadata
            ? token?.nftoken?.metadata?.name
              ? token?.nftoken?.metadata?.name
              : token?.nftoken?.metadata?.collection
              ? token?.nftoken?.metadata?.collection?.name
              : `${token?.nftoken?.nft_id?.substring(
                  0,
                  5
                )}...${token?.nftoken?.nft_id?.substring(
                  token?.nftoken?.nft_id?.length - 5,
                  token?.nftoken?.nft_id?.length - 1
                )}`
            : `${token?.nftoken?.nft_id?.substring(
                0,
                5
              )}...${token?.nftoken?.nft_id?.substring(
                token?.nftoken?.nft_id?.length - 5,
                token?.nftoken?.nft_id?.length - 1
              )}`
        } `}
      />
    );
  };

  return (
    <>
      <OptionModal
        onCancel={closeModal}
        additionalText={
          "The person you are transferring the NFToken to must accept this in their wallet"
        }
        heading={`Transferring ${
          token?.nftoken?.metadata
            ? token?.nftoken?.metadata?.name
              ? token?.nftoken?.metadata?.name
              : token?.nftoken?.metadata?.collection
              ? token?.nftoken?.metadata?.collection?.name
              : `${token?.nftoken?.nft_id?.substring(
                  0,
                  5
                )}...${token?.nftoken?.nft_id?.substring(
                  token?.nftoken?.nft_id?.length - 5,
                  token?.nftoken?.nft_id?.length - 1
                )}`
            : `${token?.nftoken?.nft_id?.substring(
                0,
                5
              )}...${token?.nftoken?.nft_id?.substring(
                token?.nftoken?.nft_id?.length - 5,
                token?.nftoken?.nft_id?.length - 1
              )}`
        } `}
      >
        <div style={{ marginTop: "2%" }}>
          <FormLabel>Destination Address</FormLabel>
          <FormInput
            placeholder="Destination Address"
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          />
          {error?.trim().length > 0 && (
            <>
              <ErrorText>{error}</ErrorText>
            </>
          )}
          <HoverOption1
            onClick={() => {
              setError();
              if (validateAddress(destination)) {
                return confirmTransfer();
              } else {
                return setError("Invalid Destination");
              }
            }}
          >
            Transfer
          </HoverOption1>
        </div>
      </OptionModal>
    </>
  );
}

export default NFTokenPage;

import React, { useEffect, useState } from "react";
import {
  MusicAboutWrapper,
  MusicContentWrapper,
  MusicDetailsContainer,
  MusicHeading,
  MusicHeadingBgImage,
  MusicHeadingContainer,
  MusicHeadingImage,
  MusicHeadingImageWrapper,
  MusicHeadingSmall,
  MusicHeadingTextWrapper,
  MusicHeadingWrapper,
  MusicInfoWrapper,
  MusicNav,
  MusicNavLink,
  MusicOptionsContainer,
  MusicPageWrapper,
  MusicPlayPauseButton,
  MusicSubheading,
  OptionButton,
  OptionMenuButton,
  SubHeading,
} from "./MusicPageElements";
import ScrollToTop from "../ScrollToTop";
import { useAlert } from "../Alert/AlertContext";
import { useNavigate } from "react-router-dom";
import { downloadFile, getSongTitle, timestampToDate } from "../../utils";
import { FaCrown, FaGift, FaRegMoneyBillAlt, FaTradeFederation } from "react-icons/fa";
import { ALERT_TYPES, CONTENT_TYPES, SLIDER_TYPES, TRANSACTION_OPTIONS } from "../../utils/Enum";
import { NavContainer } from "../NavLink/NavLinkElements";
import MusicInfo from "./MusicInfo";
import { useAuth } from "../../contexts/AuthContext";
import { useModal } from "../Modal/ModalContext";
import {MusicOptionsModal } from "../MenuOptions";
import { RoyaltyShareGifter, RoyaltySharePurchase } from "../PurchaseModal";
import ContentWrapper from "../ContentWrapper";
import ProgressBar from "../ProgressBar";
import { RegularUserList, RoyaltyTokenUserList } from "../ContentList/UserList";
import useMusic from "../../hooks/useMusic";
import { UserContentListSkeleton } from "../Skeleton";
import { FormWrapper } from "../MusicUploadsForm/MusicUploadsFormElements";
import { FormInput, FormLabel, FormWrapper as FWrapper } from "../CustomForm/CustomFormElements";
import { Timer } from "../CountdownTimer";
import MusicEditPage from "../MusicEditPage";
import { hexToRgba } from "../HexToRGBA";
import { Slider } from "../Slider";
import { ServicesWrapper } from "../Services/ServicesElements";
import { updateWebPageIcon } from "../../utils/MusicUtils";
import QRCodeForm from "../QRCodeForm";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import OptionModal from "../OptionModal";
import { ModalLoadingScreen } from "../LoadingScreen";
import { useUser } from "../../hooks/useUser";
import useArtist from "../../hooks/useArtist";
import usePodcaster from "../../hooks/usePodcaster";
import { MdCurrencyExchange } from "react-icons/md";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import { Theme } from "../WebsiteThemeElements";
import { getResizedImage } from "../../utils/FileUploadsUtils";
import RoyaltyShareStats from "./RoyaltySharesStats";
import ShareButton from "../ShareButton";

const RoyaltySharesPage = ({royalty, location,   query, id}) => {
  const { currentUser } = useAuth();
  const { goTo } = ScrollToTop();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
    const {getArtistMusicRoyaltiesByUserId} = useArtist({})
    const {getPodcasterMusicRoyaltiesByUserId} = usePodcaster({})
  const { getRoyaltyTokenOwners, getMusicRoyaltiesByType } = useMusic();
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const [currentTab, setTab] = useState("details");
  const [shareOwners, setShareOwners] = useState([]);
  const [royaltiesByType, setRoyaltiesByType] = useState({});
  const [royaltiesByUser, setRoyaltiesByUser] = useState({});
  const [isUserRoyaltiesLoaded, setUserRoyaltiesLoaded] = useState(false);
  const [isOwnersLoaded, setOwnersLoaded] = useState(false);
  const [isTypeRoyaltiesLoaded, setTypeRoyaltiesLoaded] = useState(true);
  const [isAlbumArtLoaded,setAlbumArtLoaded] = useState()
  const [albumArt, setAlbumArt] = useState(royalty?.musicDetails?.albumArt ? royalty?.musicDetails?.albumArt : royalty?.musicDetails?.thumbnail)
  const {theme} = useWebsiteTheme()

  useEffect(() => {
    let art = royalty?.musicDetails?.albumArt ? royalty?.musicDetails?.albumArt : royalty?.musicDetails?.thumbnail
    updateWebPageIcon(art)
    return () => {}
  },[])

  useEffect(() => {
    let art = royalty?.musicDetails?.albumArt ? royalty?.musicDetails?.albumArt : royalty?.musicDetails?.thumbnail
    if(!isAlbumArtLoaded){
      getResizedImage(art).then(image => {
        setAlbumArt(image)
        
      }).catch(err => {
        setAlbumArt(art)
      })
      setAlbumArtLoaded(true)
    }
  }, [])

  useEffect(() => {
    getTokenOwners()

    getMusicRoyaltiesByType(royalty.type, 0).then((royalties) => {
      if (royalties) {
        setRoyaltiesByType(royalties);
      }
      setTypeRoyaltiesLoaded(true);
    }).catch(err => {
      console.error(err)
      setTypeRoyaltiesLoaded(true);
    })

    if(royalty?.musicDetails?.episodeId ){
getPodcasterMusicRoyaltiesByUserId(royalty.musicDetails.uploadedBy, 0).then((royalties) => {
      if (royalties) {
        setRoyaltiesByUser(royalties);
      }
      setUserRoyaltiesLoaded(true);
    }).catch(err => {
      console.error(err)
      setUserRoyaltiesLoaded(true);
    });
    }
    if(royalty?.musicDetails?.songId || royalty?.musicDetails?.albumId){
getArtistMusicRoyaltiesByUserId(royalty.musicDetails.uploadedBy, 0).then((royalties) => {
      if (royalties) {
        setRoyaltiesByUser(royalties);
      }
      setUserRoyaltiesLoaded(true);
    }).catch(err => {
      console.error(err)
      setUserRoyaltiesLoaded(true);
    }); 
    }
   

    return () => {};
  }, [id]);

  useEffect(() => {
    getTokenOwners()
  
    return () => {
    }
  }, [royalty?.shareReserve, royalty?.sharesSold])
  

  const getTokenOwners = () => {
    getRoyaltyTokenOwners(royalty.tokenInfo.currency).then((owners) => {
      if (owners) {
        setShareOwners(owners);
      }
      setOwnersLoaded(true);
    }).catch(err => {
      return setOwnersLoaded(true)
    });
  }

  return (
    <>
      {" "}
      <MusicPageWrapper>
        <MusicHeadingContainer>
          <MusicHeadingBgImage
            onContextMenu={(e) => {
              e.preventDefault();
            }}
            src={
              isAlbumArtLoaded ? albumArt : royalty?.musicDetails?.albumArt
                ? royalty?.musicDetails?.albumArt
                : royalty?.musicDetails.thumbnail
            }
            alt={royalty?.ticker}
          ></MusicHeadingBgImage>
          <MusicHeadingWrapper>
            <MusicHeadingImageWrapper>
              <MusicHeadingImage
                src={
                  isAlbumArtLoaded ? albumArt : royalty?.musicDetails?.albumArt
                    ? royalty?.musicDetails?.albumArt
                    : royalty?.musicDetails.thumbnail
                }
                alt={royalty?.ticker}
                style={{borderRadius: "50%", border: `8px inset #acb2ac`}}
              />
            </MusicHeadingImageWrapper>
            <MusicHeadingTextWrapper>
              <SubHeading>
                <small>
                  <div
                    style={{ color: theme.secondaryBg, cursor: "pointer" }}
                    onClick={() =>
                      royalty?.songId
                        ? goTo(`/song/${royalty.songId}`)
                        : royalty?.albumId
                        ? goTo(`/album/${royalty.albumId}`)
                        : royalty?.episodeId
                        ? goTo(`/episode/${royalty.musicDetails.episodeId}`)
                        : "#"
                    }
                  >
                    Regium Token for{" "}{royalty?.songId ? 'song ' : royalty?.albumId ? 'album ' : royalty?.episodeId ? 'podcast episode ' : ''}
                    {
                      (royalty?.songId
                        ? getSongTitle(royalty.musicDetails)
                        : (royalty?.albumId
                            ? royalty.musicDetails.albumName
                            : royalty.musicDetails.episodeName))
                    }
                  </div>
                </small>
              </SubHeading>

              <MusicHeading>
                <FaCrown color="#a28834" style={{ marginRight: "1%" }} />
                {royalty.ticker}
              </MusicHeading>
              <MusicSubheading>
                <MusicHeading style={{ cursor: "pointer" }}>
                  {royalty.musicDetails?.artists?.length > 0
                    ? royalty.musicDetails?.artists?.map((artist, index) => {
                        if (index < royalty.musicDetails?.artists.length - 1) {
                          return (
                            <div
                              style={{ marginRight: "0.5rem" }}
                              onClick={() => goTo(`/artist/${artist.artistId}`)}
                            >
                              {artist.artistName + " · "}
                            </div>
                          );
                        } else {
                          return (
                            <div
                              onClick={() => goTo(`/artist/${artist.artistId}`)}
                            >
                              {artist.artistName}
                            </div>
                          );
                        }
                      })
                    : royalty.musicDetails?.podcasters?.map(
                        (podcaster, index) => {
                          if (
                            index <
                            royalty.musicDetails?.podcasters.length - 1
                          ) {
                            return (
                              <div
                                style={{ marginRight: "0.5rem" }}
                                onClick={() =>
                                  goTo(`/podcaster/${podcaster.podcasterId}`)
                                }
                              >
                                {podcaster.podcasterName + " · "}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onClick={() =>
                                  goTo(`/podcaster/${podcaster.podcasterId}`)
                                }
                              >
                                {podcaster.podcasterName}
                              </div>
                            );
                          }
                        }
                      )}
                </MusicHeading>
              </MusicSubheading>
              <MusicHeadingSmall>
                {new Date(timestampToDate(royalty?.createdAt)).getFullYear()}
              </MusicHeadingSmall>

              <MusicOptionsContainer>
               <><MusicPlayPauseButton
                  transparent={true}
                  onClick={() => {
                    if (royalty?.shares !== (royalty?.sharesSold)) {
                      let info = royalty;
                      openModal();
                      modalContent(
                        <RoyaltySharePurchase
                          type={
                            royalty?.musicDetails?.albumId
                              ? "album"
                              : royalty?.musicDetails?.songId
                              ? "song"
                              : "episode"
                          }
                          content={{
                            ...info?.musicDetails,
                            userRoyalties: info,
                          }}
                        />
                      );
                    }
                  }}
                >
                  <FaRegMoneyBillAlt style={{ marginRight: "2%" }} />{" "}
                  {royalty?.shares !== (royalty?.sharesSold)
                    ? `${
                        royalty.price > 0
                          ? `${royalty.price} ${royalty.currency}`
                          : "FREE"
                      } `
                    : "SOLD OUT"}
                </MusicPlayPauseButton>
                <MusicPlayPauseButton onClick ={() => {
                openModal()
                modalContent(<QRCodeForm
                  heading={`Royalty Share Trustline Create ${royalty.ticker}`}
                    type={TRANSACTION_OPTIONS.ROYALTY_TRUSTLINE_SET}
                    onFail={() => {
                      closeModal() 
                      modalContent()}}
                    onSuccess={() => {
                      closeModal() 
                      modalContent()}}
                    cancelQr={() => {
                      closeModal();
                      modalContent();
                    }}
                    body={{
                      sender: currentUser?.uid ? currentUser?.uid : undefined,
                      tokenId: royalty?.royaltyId,
                    }}
                  />)
                }}>
                  Create Trustline
                </MusicPlayPauseButton>
                
                
                {/* currentUser?.uid && (
                  <OptionButton
                  onClick={() =>
                    {openModal()
                    modalContent(<MusicOptionsModal content={royalty} type={ CONTENT_TYPES.ROYALTY_SHARE}/>)
                  }
                }
                  >
                    <OptionMenuButton />
                  </OptionButton>
                ) */}</>
              </MusicOptionsContainer>
              <MusicOptionsContainer>
                <MusicPlayPauseButton
                  transparent={true}
                  onClick={() => {
                    openModal()
                    modalContent(<OptionModal heading={`Select a Dex to Trade $${royalty?.ticker}`}>
                      <HoverOption onClick ={() => {downloadFile(`https://xumm.app/detect/xapp:xumm.dex?issuer=rwNy6GTv47H9DyLnGkz6LA4PTKcKRSonAr&currency=${royalty?.tokenInfo?.currency}`)
                      closeModal()
                      modalContent()
                  }}>
                        Xumm Dex
                      </HoverOption>
                      <HoverOption onClick ={() => {downloadFile(`https://sologenic.org/trade?network=mainnet&market=${royalty?.tokenInfo?.currency}%2BrwNy6GTv47H9DyLnGkz6LA4PTKcKRSonAr%2FXRP`)
                      closeModal()
                      modalContent()
                  }}>Sologenic Dex</HoverOption>
                    </OptionModal>)
                    
                 }}
                >
                  <MdCurrencyExchange/> Trade on Dex
                </MusicPlayPauseButton>
                <MusicPlayPauseButton transparent onClick={async () => {
                  openModal()
                  modalContent(<ShareButton url={`https://sonarmuse.org/regium/${royalty?.royaltyId}`} media={royalty?.musicDetails?.albumArt ? royalty?.musicDetails?.albumArt : royalty?.musicDetails?.thumbnail} 
                    title={`Investing in music has never been this easy or fun! Check out the  ${(royalty?.songId ? getSongTitle(royalty.musicDetails)
                        : (royalty?.albumId
                            ? royalty.musicDetails.albumName
                            : royalty.musicDetails.episodeName))
                    } (${royalty?.ticker}) #Regium token on Sonar Muse`} />);
                }}>
                    Share
                 </MusicPlayPauseButton>
              </MusicOptionsContainer>
            </MusicHeadingTextWrapper>
          </MusicHeadingWrapper>
        </MusicHeadingContainer>
        <MusicAboutWrapper>
          This is the regium share token for the{" "}
          {royalty?.songId
            ? `song ${getSongTitle(royalty.musicDetails)}`
            : royalty?.albumId
            ? `album ${royalty.musicDetails?.albumName}`
            : `podacast episode ${royalty.musicDetails.episodeName}`}
          . Owning a ${royalty.ticker} regium share token allows the shareholders to earn
          passively from the music's sales and/or streams.
        </MusicAboutWrapper>
        <MusicAboutWrapper><b>Disclaimer: This regium share token can be taken back and removed if the artist removes the music from the platform. (XRPL clawback feature has been enabled for token issuer)</b></MusicAboutWrapper>
        <NavContainer>
          <MusicNav>
            <MusicNavLink
              onClick={() => setTab("details")}
              active={currentTab === "details"}
            >
              Regium Share Details
            </MusicNavLink>
            <MusicNavLink
              onClick={() => setTab("prevPayouts")}
              active={currentTab === "prevPayouts"}
            >
              Payout History
            </MusicNavLink>

            {currentUser?.uid && isOwnersLoaded && (
              <MusicNavLink
                onClick={() => setTab("stats")}
                active={currentTab === "stats"}
              >
                Music Stats
              </MusicNavLink>
            )}
          </MusicNav>
        </NavContainer>

        {currentTab === "prevPayouts" && (
          <><ContentWrapper>
            <Timer heading={`$${royalty?.ticker} Payout Epoch ${royalty.prevPayouts?.length > 0 ? (royalty.prevPayouts?.length + 1) : 1}`} isPage={false} date={royalty.nextPayout} details={`Snapshot of $${royalty?.ticker} Shareholders for payout epoch ${royalty.prevPayouts?.length > 0 ? (royalty.prevPayouts?.length + 1) : 1} ${!royalty?.snapshot ? `will take place on ${new Date(new Date()?.setDate(new Date(royalty.nextPayout).getDate() - 3)).toISOString().split("T")[0]}`: 'has been taken.'}`} /* img={royalty?.musicDetails?.albumArt
                ? royalty?.musicDetails?.albumArt
                : royalty?.musicDetails.thumbnail} */ />
          </ContentWrapper>
          <MusicInfo type={CONTENT_TYPES.ROYALTY_SHARE} content={royalty} /></>
        )}

        {currentTab === "details" && (
          <MusicContentWrapper>
            <MusicInfoWrapper>
              <ProgressBar
                message={`${parseFloat(
                  (royalty?.sharesSold / royalty?.shares) * 100
                )}% of ${royalty?.shares} shares Sold`}
                width={(royalty?.sharesSold / royalty?.shares) * 100}
                color={
                  (royalty?.sharesSold / royalty?.shares) * 100 < 50
                    ? Theme({}).successColor
                    : (royalty?.sharesSold / royalty?.shares) * 100 < 80
                    ? Theme({}).warningColor
                    : Theme({}).errorColor
                }
              />
            </MusicInfoWrapper>
            <ContentWrapper heading={`Regium Token Info`}>
              <ContentWrapper>
              <FWrapper style={{background: `${hexToRgba(theme.accentColor, 0.03)}`}}>
                <ServicesWrapper numGrid={2} style={{width: "100%"}}>
              <FormWrapper style={{alignContent: "space-evenly", flexDirection: "column"}}>
                {" "}
                <FormLabel style={{justifyContent: "center", marginBottom: "1%", fontWeight: "bold"}}>Ticker:</FormLabel>
                <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  {royalty?.ticker}{" "}
                </FormLabel>
              </FormWrapper>
              <FormWrapper style={{alignContent: "space-evenly", flexDirection: "column"}}>
                {" "}
                <FormLabel style={{justifyContent: "center", marginBottom: "1%", fontWeight: "bold", textAlign: "center"}}>Type:</FormLabel>
                <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  {royalty?.songId ? 'Song' : royalty?.albumId ? 'Album' : royalty?.episodeId ? 'Podcast Episode' : ''}{" "}
                </FormLabel>
              </FormWrapper>
              <FormWrapper style={{alignContent: "space-evenly", flexDirection: "column"}}>
                {" "}
                <FormLabel style={{justifyContent: "center", marginBottom: "1%", fontWeight: "bold", textAlign: "center"}}>Music Percentage Split:</FormLabel>
                <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  {royalty?.percentage}{"%"}
                </FormLabel>
              </FormWrapper>
              <FormWrapper style={{alignContent: "space-evenly", flexDirection: "column"}}>
                {" "}
                <FormLabel style={{justifyContent: "center", marginBottom: "1%", fontWeight: "bold", textAlign: "center"}}>Payout Per Regium Token:</FormLabel>
                <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  {royalty?.percentage/100}%{" "}
                </FormLabel>
              </FormWrapper>
              <FormWrapper style={{alignContent: "space-evenly", flexDirection: "column"}}>
                {" "}
                <FormLabel style={{justifyContent: "center", marginBottom: "1%", fontWeight: "bold", textAlign: "center"}}>No. Eligible Shareholders:</FormLabel>
               {isOwnersLoaded && shareOwners?.owners?.length > 0 &&  <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  {shareOwners?.owners?.length}
                </FormLabel> }
                {!isOwnersLoaded && <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  N/A
                </FormLabel> }
              </FormWrapper>
              <FormWrapper style={{alignContent: "space-evenly", flexDirection: "column"}}>
                {" "}
                <FormLabel style={{justifyContent: "center", marginBottom: "1%", fontWeight: "bold", textAlign: "center"}}>Next Payout:
                
                </FormLabel>
                <FormLabel style={{justifyContent: "center", marginBottom: "1%"}}>
                  {`${new Date(royalty.nextPayout).getDate()}/${new Date(
                    royalty.nextPayout
                  ).getMonth() + 1}/${new Date(
                    royalty.nextPayout
                  ).getFullYear()}`}{" "}
                </FormLabel>
              </FormWrapper></ServicesWrapper>
             </FWrapper></ContentWrapper></ContentWrapper>
             {isOwnersLoaded && shareOwners?.owners?.length > 0 && (
              <ContentWrapper
                heading={`Eligible $${royalty.ticker} Regium Shareholders`}
              >
                <ContentWrapper>{<RoyaltyTokenUserList content={shareOwners?.owners} />}</ContentWrapper>
              </ContentWrapper>
            )}
            {!isOwnersLoaded && (
              <ContentWrapper
                heading={`Eligible $${royalty.ticker} Regium Shareholders`}
              >
                <UserContentListSkeleton />
              </ContentWrapper>
            )}
          </MusicContentWrapper>
        )}
        {currentTab === "stats" && (
          //Validate user to see if they own shares
          //allow to view music stats
          <MusicContentWrapper>
            <ContentWrapper>
            {!shareOwners?.owners?.some(owner => owner?.uid === currentUser?.uid) && currentUser?.uid !== royalty?.musicDetails?.uploadedBy  && <><MusicAboutWrapper>Owning a ${royalty.ticker} regium token share will allow shareholders to view the stats for {
                      (royalty.type = "song"
                        ? getSongTitle(royalty.musicDetails)
                        : royalty.type = "album"
                            ? royalty.musicDetails.albumName
                            : royalty.musicDetails.episodeName)
                    }</MusicAboutWrapper>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}><MusicPlayPauseButton
                  transparent={true}
                  onClick={() => {
                    if (royalty?.shares !== (royalty?.sharesSold)) {
                      let info = royalty;
                      openModal();
                      modalContent(
                        <RoyaltySharePurchase
                          type={
                            royalty?.musicDetails?.albumId
                              ? "album"
                              : royalty?.musicDetails?.songId
                              ? "song"
                              : "episode"
                          }
                          content={{
                            ...info?.musicDetails,
                            userRoyalties: info,
                          }}
                        />
                      );
                    }
                  }}
                >
                  <FaRegMoneyBillAlt style={{ marginRight: "2%" }} />{" "}
                  {royalty?.shares !== (royalty?.sharesSold)
                    ? `${
                        royalty.price > 0
                          ? `${royalty.price} ${royalty.currency}`
                          : "FREE"
                      } `
                    : "SOLD OUT"}
                </MusicPlayPauseButton>
                <MusicPlayPauseButton
                  transparent={true}
                  onClick={() => {
                    openModal()
                    modalContent(<OptionModal heading={`Select a Dex to Trade $${royalty?.ticker}`}>
                      <HoverOption onClick ={() => {downloadFile(`https://xumm.app/detect/xapp:xumm.dex?issuer=rwNy6GTv47H9DyLnGkz6LA4PTKcKRSonAr&currency=${royalty?.tokenInfo?.currency}`)
                      closeModal()
                      modalContent()
                  }}>
                        Xumm Dex
                      </HoverOption>
                      <HoverOption onClick ={() => {downloadFile(`https://sologenic.org/trade?network=mainnet&market=${royalty?.tokenInfo?.currency}%2BrwNy6GTv47H9DyLnGkz6LA4PTKcKRSonAr%2FXRP`)
                      closeModal()
                      modalContent()
                  }}>Sologenic Dex</HoverOption>
                    </OptionModal>)
                    
                 }}
                >
                  <MdCurrencyExchange/> Trade on Dex
                </MusicPlayPauseButton></div>
                    </>
            }

            {(shareOwners?.owners?.some(owner => owner?.uid === currentUser?.uid) || currentUser?.uid === royalty?.musicDetails?.uploadedBy) && <RoyaltyShareStats royalty={royalty}/>}
            </ContentWrapper>
          </MusicContentWrapper>
        )}
      
            {isTypeRoyaltiesLoaded && royaltiesByType?.royalties?.filter(r => r?.royaltyId !== royalty?.royaltyId)?.length > 0 && 
            <ContentWrapper heading={`Other ${royalty?.songId ? 'Song' : royalty?.albumId ? 'Album' : royalty?.episodeId ? 'Podcast Episode' : ''} Regium Shares`} onClick={() => royaltiesByType?.length > 10 &&
              navigate(`/regium/${royalty?.songId ? 'song' : royalty?.albumId ? 'album' : royalty?.episodeId ? 'episode' : ''}/similarRoyalties`)
            } subheading={royaltiesByType?.length > 10 && 'View All'}>
              <Slider type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE} id={'type royalties'} slides={royaltiesByType?.royalties.filter(r => r?.royaltyId !== royalty?.royaltyId)}/>
            </ContentWrapper>
            
            }
            {
              !isTypeRoyaltiesLoaded &&
              <ContentWrapper heading={`Other ${royalty?.songId ? 'Song' : royalty?.albumId ? 'Album' : royalty?.episodeId ? 'Podcast Episode' : ''} Regium Shares`}>
              <Slider type={SLIDER_TYPES.PURCHASE_SKELETON} id={'type royalties'}/>
            </ContentWrapper>

            }

          {isUserRoyaltiesLoaded && royaltiesByUser?.royalties.filter(r => r?.royaltyId !== royalty?.royaltyId)?.length > 0 && 
            <ContentWrapper heading={`Other Regium Shares by ${royalty?.musicDetails.artists ? 'Artist' : "Podcaster"}`}
            onClick={() => royaltiesByUser?.length > 10 &&
                        navigate(`/artist/${royalty?.musicDetails.uploadedBy}/regiums`)
                      } subheading={royaltiesByUser?.length > 10 && 'View All'}
            >
              <Slider type={SLIDER_TYPES.MUSIC_ROYALTY_SHARE} id={'user royalties'} slides={royaltiesByUser?.royalties.filter(r => r?.royaltyId !== royalty?.royaltyId)}/>
            </ContentWrapper>
            
            }
            {
              !isUserRoyaltiesLoaded &&
              <ContentWrapper heading={`Other Regium Shares by ${royalty?.musicDetails.artists ? 'Artist' : "Podcaster"}`}>
              <Slider type={SLIDER_TYPES.PURCHASE_SKELETON} id={'user royalties'}/>
            </ContentWrapper>

            }

      </MusicPageWrapper>
    </>
  );
};

const RoyaltyShareReserveClaimer = ({content, type }) => {
  const {openModal, modalContent, closeModal} = useModal()
  const { addAlert } = useAlert();
  const {currentUser} = useAuth()
  const { validateUserRoyaltyTrustline } = useUser({});
  const [isReturn, setReturn] = useState(false)
  const [quantity, setQuantity] = useState(0)
  const [royaltyInfo, setRoyaltyInfo] = useState(content?.userRoyalties);



  const handleInput = (e) => {
    switch (e.target.name) {
      case "quantity":
        console.log(e)
        if(e.target.value?.length > 0 && (e.target.value <= royaltyInfo.shareReserve) && isNaN(e.target.value) === false){
          if(parseInt(e.target.value[0]) === 0){
            setQuantity(parseInt(e.target.value.substring(1,e.target.value.length)));
          return
          }
          setQuantity(parseInt(e.target.value));
          return
          }if(e.target.value?.length === 0){
            setQuantity(0);
          }
          break;
          default: 
          break;

        }
      }

      

      const onClaimSuccess = (data) => {
        openModal();
        modalContent(
          <OptionModal heading={"Regium Share Claim Successful"}>
            {/* <FormImgWrapper>
              <FormImg src={content.albumArt} alt={content.songName} />
            </FormImgWrapper> */}
            <HoverOption onClick={() => {
              closeModal()
              modalContent()
            }}>OK</HoverOption>
            <HoverOption
              onClick={() => {
                downloadFile(`https://xrplexplorer.com/explorer/${data?.txid}`);
              }}
            >
              View Transaction {data?.txid?.substring(0, 15)}...
            </HoverOption>
          </OptionModal>
        );
      };
    
      const onClaimFail = () => {
        closeModal();
        modalContent();
      };

      const claimReserve = () => {
        openModal()
        modalContent(<QRCodeForm
          heading={`Claiming ${
            type === "song"
              ? content?.songName
              : type === "album"
              ? content.albumName
              : content?.episodeName
          } Regium Share (${royaltyInfo.ticker}) Reserves`}
          type={TRANSACTION_OPTIONS.CLAIM_MUSIC_ROYALTY_RESERVES}
          onFail={onClaimFail}
          onSuccess={onClaimSuccess}
          cancelQr={() => {
            closeModal();
            modalContent();
          }}
          body={{
            sender: currentUser?.uid,
            type: type,
            shareReturn: quantity,
            royaltyId: royaltyInfo?.royaltyId,
            instruction: `${currentUser?.uid} is signing transaction to claim ${
              type === "song"
                ? content?.songName
                : type === "album"
                ? content.albumName
                : content?.episodeId
            } royalty share token ${royaltyInfo.ticker} reserves`,
            memo: `${currentUser?.uid} is signing transaction to claim ${
              type === "song"
                ? content?.songName
                : type === "album"
                ? content.albumName
                : content?.episodeId
            } royalty share token ${royaltyInfo.ticker} reserves`,
          }}
          
          />)
      }

      const continueClaim = () => {
        modalContent(<OptionModal heading={"Trustline Successfully Added"}>
        <HoverOption onClick={claimReserve}>
          Continue Claiming
        </HoverOption>
        <HoverOption onClick={() => {
          closeModal()
          modalContent()
        }}>
          Cancel
        </HoverOption>
        </OptionModal>)
      }

      const validateUserTrustline = async () => {
        modalContent(<OptionModal><ModalLoadingScreen text={'Checking Wallet Trustlines. Please wait'}/></OptionModal>)
        await validateUserRoyaltyTrustline(currentUser?.uid, royaltyInfo?.royaltyId)
          .then((validation) => {
            if (validation.exists) {
              return claimReserve()
            }
            if (!validation.exists) {
              return modalContent(
                <OptionModal
                  heading={`No (${royaltyInfo.ticker}) Regium Share Token Trustline Set for ${
                    type === "song"
                      ? content?.songName
                      : type === "album"
                      ? content.albumName
                      : content?.episodeId
                  } `}
                  additionalText={`You do not have the ${royaltyInfo.ticker} regium trustline set. Please set up trustline before continuing`}
                >
                  <HoverOption
                    onClick={() => {
                      openModal()
                      modalContent(<QRCodeForm
                        heading={`Royalty Share Trustline Create ${royaltyInfo.ticker}`}
                          type={TRANSACTION_OPTIONS.ROYALTY_TRUSTLINE_SET}
                          onFail={() => {
                            closeModal() 
                            modalContent()}}
                          onSuccess={continueClaim}
                          cancelQr={() => {
                            closeModal();
                            modalContent();
                          }}
                          body={{
                            sender: currentUser?.uid,
                            tokenId: royaltyInfo?.royaltyId,
                          }}
                        />)
                    }}
                  >
                    {" "}
                    Create Trustline
                  </HoverOption>
                  <HoverOption
                    onClick={() => {
                      closeModal()
                      modalContent()
                    }}
                  >
                    {" "}
                    Close
                  </HoverOption>
                </OptionModal>
              );
            } else {
              openModal();
              return modalContent(
                <OptionModal
                  heading={"Something went wrong"}
                  onCancel={() => {
                    closeModal();
                    modalContent();
                  }}
                >
                  <HoverOption
                    onClick={() => {
                      openModal();
                      modalContent(<RoyaltyShareReserveClaimer content={content} type={type}  />);
                    }}
                  >
                    Try Again
                  </HoverOption>
                </OptionModal>
              );
            }
          })
          .catch((err) => {
            openModal();
            return modalContent(
              <OptionModal
                heading={"Something went wrong"}
                onCancel={() => {
                  closeModal();
                  modalContent();
                }}
              >
                <HoverOption
                  onClick={() => {
                    openModal();
                    modalContent(<RoyaltyShareReserveClaimer content={content} type={type} />);
                  }}
                >
                  Try Again
                </HoverOption>
              </OptionModal>
            );
          });
      };

  return (
    <>
    <OptionModal onCancel={()=>{
      closeModal()
      modalContent()
    }} heading={'Regium Share Reserve Claimer'} additionalText={'Claim regium shares that you have reserved for yourself'}>
    <br/>
     {isReturn && <> <FormLabel>
        Quantity to Return
      </FormLabel>
      <FormInput
        name={"quantity"}
        onChange={handleInput}
        placeholder={"How many shares do you want to return back to supply"}
        min={0}
        max={royaltyInfo.shareReserve}
        value={quantity}
      /></>
      }
      <small style={{justifyContent: 'center'}}><i><b>Note: You will not be able to reserve or claim any new regium share tokens.</b> </i></small>
      <HoverOption onClick={() => {
        if(!isReturn){
          return validateUserTrustline()
        }
        if(isReturn && (quantity >= 0 && quantity <= royaltyInfo.shareReserve)){
          return validateUserTrustline()
        }else{
         return addAlert({
            title: "Claim Error",
            type: ALERT_TYPES.DANGER,
            message: `Enter valid quantity`,
          });
        }
      }}>Claim Reserve</HoverOption>
      {!isReturn && <HoverOption onClick={() => setReturn(true)}>Return Some Shares Back to Supply</HoverOption>}
    </OptionModal>
    
    </>
  )
}

export default RoyaltySharesPage;

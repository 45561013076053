import React, { useEffect, useState } from 'react'
import { HoverOption } from '../MenuOptions/MenuOptionsElements';
import { useUser } from '../../hooks/useUser';
import { useAuth } from '../../contexts/AuthContext';
import OptionModal from '../OptionModal';
import { useModal } from '../Modal/ModalContext';
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements';
import { useNavigate } from 'react-router-dom';
import LoadingScreen, { ModalLoadingScreen } from '../LoadingScreen';
import PlaylistList from '../ContentList/PlaylistList';
import { FormContent } from '../CustomForm/CustomFormElements';

const PlaylistViewer = () => {
    const {currentUser} = useAuth()
    const [currentTab, setCurrentTab] = useState("user")
    const [playlists, setPlaylists] = useState([])
    const [collabPlaylists, setCollabPlaylists] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [isCPLoaded, setCPLoaded] = useState(false)
    const {getPlaylistsByUser, getCollaborativePlaylistsByUser} = useUser({})
    const {modalContent, toggleModal, openModal, closeModal} = useModal()
    const navigate = useNavigate()

    useEffect(() => {
        getPlaylistsByUser(currentUser?.uid, "all")
      .then((pl) => {
         if (pl && !pl?.error) {
          setPlaylists(pl)
          setLoaded(true)
        }
       
      })
      .catch((err) => {
        //console.log(err);
        setLoaded(true)
        return;
      });
        getCollaborativePlaylistsByUser(currentUser?.uid, "all").then(cPl => {
            if(cPl && !cPl.error){
                setCollabPlaylists(cPl)
                setCPLoaded(true)
            }
      }).catch((err) => {
        setCPLoaded(true)
      })
        return () => {}
    },[])

  return (
    <>
    <OptionModal

                heading={currentTab === 'user'? "My Playlists" : "Collaborative Playlists"}
                onCancel={() => {
                  modalContent();
                  toggleModal();
                }}
              >
                 <NavContainer><Nav>
                      <NavLink onClick={() => setCurrentTab('user')} active={currentTab === 'user'}>My Playlists</NavLink>
                      <NavLink onClick={() => setCurrentTab('collaborate')} active={currentTab === 'collaborate'}>Collab Playlists</NavLink>
                      </Nav></NavContainer>
               {currentTab === 'user' && isLoaded && playlists?.length === 0 && <>No Playlists</>}
               {currentTab === 'collaborate' && isLoaded && collabPlaylists?.length === 0 && <>No Playlists</>}
               <FormContent style={{maxHeight: '60vh'}}>
                {currentTab === 'user' && isLoaded && playlists?.length >= 1 &&
                  <PlaylistList content={playlists?.playlists}  onClick={(index) => {
                    modalContent();
                    closeModal();
                    navigate( `/playlist/${playlists?.playlists[index].playlistId}`);
                  }}/>}
                  {currentTab === "collaborate" && isCPLoaded && collabPlaylists?.length >= 1 &&
                  <PlaylistList content={collabPlaylists?.playlists}  onClick={(index) => {
                    modalContent();
                    closeModal();
                    navigate( `/playlist/${collabPlaylists?.playlists[index].playlistId}`);
                  }}/>}</FormContent>
                   {!isLoaded && currentTab === 'user' && <ModalLoadingScreen text={'Loading Playlists'} transparent={true}/>}
                  {!isCPLoaded && currentTab === 'collaborate' && <ModalLoadingScreen text={'Loading Playlists'} transparent={true}/>}
                  
              </OptionModal>
    </>
  )
}

export default PlaylistViewer
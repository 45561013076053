import React from 'react'
import ImageUtils from '../../../utils/ImageUtils';
import { VerifiedBadge } from '../../Profile/ProfileDetails/ProfileDetailsElements';
import { Button, Button1, ButtonWrapper, DetailsContainer, DetailsText, ImageWrapper, Img, Table, TableData, TableRow } from '../ContentListElements';

const ArtistList = ({content, onButtonClick}) => {
  const {DEFAULT_IMAGE, verifiedBadge } = ImageUtils();

  return (
    <Table>
    {content.map((artist, index) => {
      return (
          <TableRow
            key={index}
            id={artist?.artistId}
          >
            <TableData>
              <ImageWrapper>
                <Img
                  isCircular={true}
                  src={artist?.artistSettings?.profilePictureType === "custom" && artist?.profilePicture ?
                  artist?.profilePicture : artist?.avatar_url
                      ? artist?.avatar_url
                      : DEFAULT_IMAGE(30)
                  }
                  alt={
                    artist?.artistName ? artist?.artistName : artist
                  }
                />
              </ImageWrapper>
            </TableData>
            <TableData onClick={() => (onButtonClick && artist?.artistId) ?  onButtonClick(artist) : {}}>
              <DetailsContainer>
                <DetailsText>{artist?.artistName}{artist?.isVerified && <VerifiedBadge src={verifiedBadge}/>}</DetailsText>
              </DetailsContainer>
            </TableData>
            {!onButtonClick && (
              <><TableData>
                <ButtonWrapper style={{opacity: '0'}}>
                  <Button1>Play</Button1>
                </ButtonWrapper>
              </TableData>

          <TableData>
                <ButtonWrapper style={{opacity: '0'}}>
                  <Button1>Play</Button1>
                </ButtonWrapper>
              </TableData>

          <TableData>
{" "}
    {artist?.isFollowing && (
      <ButtonWrapper style={{opacity: '0'}}>
      <Button1>Unfollow</Button1>
      </ButtonWrapper>
) } {!artist?.isFollowing &&  (<ButtonWrapper style={{opacity: '0'}}>
  <Button1>Follow</Button1>
</ButtonWrapper>)}
</TableData></>
            )}
          </TableRow>
      );
    })}
  </Table>
  )
}

export default ArtistList
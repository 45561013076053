import { hexToStr, timestampToDate } from "../index";
import { db } from "../../components/Authorization/firebase";
import {
  query,
  doc,
  getDoc,
  collection,
  getDocs,
  where,
  limit,
  orderBy,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import axiosInstance from "../../components/Authorization/client";

//const axios = axiosInstance;

export const getPlaylistInfo = (playlistId, setPlaylist) => {
  onSnapshot(doc(db, `playlists/${playlistId}`), async (playlistSnapshot) => {
    if (playlistSnapshot.exists) {
      let songs = [];
      let playlist = {}
      if (playlistSnapshot?.data()?.songs?.length > 0) {
        songs = await getSongs(playlistSnapshot?.data()?.songs);
        playlist = {
          playlistId: playlistSnapshot.id,
          ...playlistSnapshot.data(),
          curator: {curatorId: playlistSnapshot?.data()?.curatorId},
          songs: songs,
        }
        setPlaylist({
          playlistId: playlistSnapshot.id,
          ...playlistSnapshot?.data(),
          curator: {curatorId: playlistSnapshot?.data()?.curatorId},
          songs: songs,
        });
      } else {
        playlist = {
          playlistId: playlistSnapshot.id,
          ...playlistSnapshot?.data(),
        }
        setPlaylist({
          playlistId: playlistSnapshot.id,
          ...playlistSnapshot?.data(),
        });
      }
      return
    } else {
      return;
    }
  }, async (err) => {
    console.error(err)
    return
  } );
};

export const getNewSongReleases = (page,setReleases) => {
    return onSnapshot(query(collection(db,"songs")
    ,where("isStreamable", "==", true)
    ,orderBy("createdAt", "desc")), async (collection) => {
        if (!collection.empty) {
            let singles = collection.docs.filter((song) => !song?.data()?.album);
            let length = singles?.length;
            singles = singles.filter((song, index, self) =>
              page === "all"
                ? index < self?.length
                : page && page * 10 <= self?.length
                ? index >= 10 * page &&
                  (10 * page + 10 > self?.length //last page conditional checker
                    ? index < self?.length
                    : index < 10 * page + 10)
                : index < 10
            );
            let songs = await getSongs(singles.map((song) => {
              return song.id;
            }));
            return setReleases({ songs: songs, length: length });
            
          } else {
            return
          }
    }
    )
}

export const getNewAlbumReleases = (page, setReleases) => {
    return onSnapshot(query(collection(db,"albums")
    ,where("isStreamable", "==", true)
    ,orderBy("createdAt", "desc")), async (collection) => {
        if (!collection.empty) {
            let albumsId = {
              ids: collection.docs
                .filter((album, index, self) =>
                  page === "all"
                    ? index < self?.length
                    : page && page * 10 <= self?.length
                    ? index >= 10 * page &&
                      (10 * page + 10 > self.length //last page conditional checker
                        ? index < self?.length
                        : index < 10 * page + 10)
                    : index < 10
                )
                .map((album) => {
                  return album.id;
                }),
              length: collection?.docs?.length,
            };
            let albums = await getAlbums(albumsId.ids);
    
            return setReleases({ albums: albums, length: albumsId.length });
          } else {
            return 
          }
    }
    )
}

export const getNewPodcastEpisodes = (page, setEpisodes) => {
    return onSnapshot(query(collection(db,"albums")
    ,where("isStreamable", "==", true)
    ,orderBy("releaseDate", "desc")), async (collection) => {
        if (!collection.empty) {
            let availEpisodes = collection.docs
            let length = availEpisodes?.length;
            availEpisodes = availEpisodes.filter((episode, index, self) =>
              page === "all"
                ? index < self?.length
                : page && page * 10 <= self?.length
                ? index >= 10 * page &&
                  (10 * page + 10 > self?.length //last page conditional checker
                    ? index < self?.length
                    : index < 10 * page + 10)
                : index < 10
            );
            let episodesId = {
              ids: availEpisodes.map((episode) => {
                return episode.id;
              }),
              length: length,
            };
            let episodes = await getEpisodes(episodesId.ids);
      return setEpisodes({ episodes: episodes, length: episodesId?.length });
          } else {
            return 
          }

    })
}

export const getSong = async(id) => {
  return await axiosInstance.get(`/api/${id}/getSong`)
    .then((song) => {
      if(song?.data && !song?.data?.error){
        return song
      }
        return false
    })
    .catch((err) => {
      return false;
    });
};

export const getSongs = async (songs) => {
  let s = []
  let songRequests = []
  for(let i = 0; i < songs.length; i++){
      songRequests.push(await axiosInstance.get(`/api/${songs[i]}/getSong`))
  }
  return await Promise.all(songRequests).then((req) => {
    s = req.filter(song => song.data && !song.data?.error).map(s => {
      return s?.data
    })
    return s
  }).catch(err => {
    return false
  })
};

const getAlbum = async(id) => {
  let albumInfo = await await axiosInstance.get(`/api/${id}/getAlbum`).then(async(response) => {
   if(response.data){
     return response.data
   }
   return false
 }).catch((err) => {
   return false;
 });
 let songs = []

 if(albumInfo?.songs?.length > 5){

    songs = await getSongs(albumInfo.songs)
   
    
   albumInfo = {
     ...albumInfo,
     songs: songs
   }
   return albumInfo
 }else{
   return albumInfo
 }
};

export const getAlbums = async(albums) => {

    let a = []
    for(let i = 0; i < albums.length; i++){
        let album = await getAlbum(albums[i])
        if(album){
          a.push(album)
        }
    }
    return a
  };
   //get Episode information from database
export const getEpisode = async(id) => {
    return await axiosInstance.get(`/api/${id}/getEpisode`)
      .then((episode) => {
        if(episode?.data){
          return episode.data
        }
        return false
        
      })
      .catch((err) => {
        return false;
      });
  };

export const getEpisodes = async(episodes) => {

    let e = []
    for(let i = 0; i < episodes.length; i++){
        let episode = await getEpisode(episodes[i])
        if(episode){
          e.push(episode)
        }
    }
    return e
  };

export const getNFTokenCollection = async (collectionId) => {
    let collectionInfo =  await axiosInstance.get(`/api/${collectionId}/getCollection`)
      .then((collection) => {
        if(collection?.data){
        return collection.data
      }
      return false
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
    if(collectionInfo !== false){
      let albums = []
      let songs = []

      if(collectionInfo?.albums && collectionInfo?.albums?.length > 0){
        
         albums = await getAlbums(collectionInfo?.albums)
          
      }
      if(collectionInfo?.songs && collectionInfo?.songs?.length > 0){
        
          songs = await getSongs(collectionInfo.songs)
         

      }
       collectionInfo = {
        ...collectionInfo,
        albums: albums?.length > 0 ? albums : undefined,
        songs: songs?.length > 0 ? songs : undefined
      }
      return collectionInfo
    }else{
      return false
    }
    
  };

export const getSongInfo = async (songId, setSongInfo) => {
  return onSnapshot(doc(db,`songs/${songId}`), async (songSnapshot) => {
    if(songSnapshot?.exists && songSnapshot?.data()){
    return await await axiosInstance.get(`/api/${songId}/getSong`).then((song) => {
      if(song.data && !song.data.error){
      setSongInfo({...song.data,
        ...songSnapshot?.data(),
        artists: song?.data?.artists,
        album: song?.data?.album,
        genre: song?.data?.genre,
        userRoyalties: song?.data?.isUserRoyalties ? song?.data?.userRoyalties : undefined,
        releaseDate: {_seconds : songSnapshot?.data().releaseDate?.seconds,
          _nanoseconds:  songSnapshot?.data().releaseDate?.nanoseconds},
        featuredArtists: song?.data?.featuredArtists,
        subGenres: song?.data?.subGenres,
        songCredits: song?.data?.songCredits
      })
    }
    return
    })}
    return false
  })
}

export const getAlbumInfo = async (albumId, setAlbumInfo) => {
  return onSnapshot(doc(db,`albums/${albumId}`), async (albumSnapshot) => {
      let songs = []
      if(albumSnapshot?.exists && albumSnapshot?.data()){
        return await axiosInstance.get(`/api/${albumId}/getAlbum`).then(async (a) => {
          if(a.data && !a.data.error){
          let albumInfo = a?.data
          if(albumInfo?.songs?.length > 5){
            songs = await getSongs(albumInfo.songs)
           albumInfo = {
             ...albumInfo,
             songs: songs
           }
           setAlbumInfo({...albumInfo,
            ...albumSnapshot.data(),
            songs: albumInfo?.songs,
            artists: albumInfo?.artists,
            userRoyalties: albumInfo?.isUserRoyalties ? albumInfo?.userRoyalties : undefined,
            releaseDate: {_seconds : albumSnapshot?.data()?.releaseDate.seconds,
              _nanoseconds:  albumSnapshot?.data().releaseDate.nanoseconds},
                genre: albumInfo?.genre
          })
         }else{
           return setAlbumInfo(prev => {
            return {...albumInfo,
            ...albumSnapshot.data(),
            songs: albumInfo?.songs,
            artists: albumInfo?.artists,
            userRoyalties: albumSnapshot.data()?.isUserRoyalties ? prev?.userRoyalties: undefined,
            releaseDate: albumInfo.releaseDate,
            genre: albumInfo?.genre
          }
        })
         }
        }
        })
      }
      return false
  })
}

export const getVideoInfo = async (videoId, setVideoInfo) => {
  return onSnapshot(doc(db,`videos/${videoId}`), async (videoSnapshot) => {
      if(videoSnapshot?.exists && videoSnapshot?.data()){
        return await axiosInstance.get(`/api/${videoId}/getVideo`).then(async (v) => {
          let videoInfo = v?.data
          if(v.data && !v.data.error){
           setVideoInfo({
            ...videoInfo,
            ...videoSnapshot.data(),
            releaseDate: {_seconds : videoSnapshot?.data().releaseDate.seconds,
              _nanoseconds:  videoSnapshot?.data().releaseDate.nanoseconds},
              artists: videoInfo?.artists,
              featuredArtists: videoInfo?.featuredArtists,
                genre: videoInfo?.genre,
                credits: videoInfo?.data?.credits
              })
         }else{
           return setVideoInfo({...videoInfo,
            ...videoSnapshot.data(),
            artists: videoInfo?.artists,
            releaseDate: videoInfo.releaseDate,
            featuredArtists: videoInfo?.featuredArtists,
            genre: videoInfo?.genre,
            credits: videoInfo?.data?.credits
          })
         }
        
        })
      }
      return false
  })
}
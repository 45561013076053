import React, { useEffect, useState } from 'react'
import OptionModal from '../OptionModal';
import { HoverOption } from '../MenuOptions/MenuOptionsElements';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../Modal/ModalContext';
import { useUser } from '../../hooks/useUser';
import { useAuth } from '../../contexts/AuthContext';
import { FormButton1, FormContent } from '../CustomForm/CustomFormElements';
import PlaylistCreator from '../PlaylistCreator';
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements';
import LoadingScreen, { ModalLoadingScreen } from '../LoadingScreen';
import { Button1 } from '../ButtonElement';
import PlaylistList from '../ContentList/PlaylistList';
import { useAlert } from '../Alert/AlertContext';
import { ALERT_TYPES } from '../../utils/Enum';

const PlaylistSelector = ({songId}) => {
    const {currentUser} = useAuth()
    const [currentTab, setCurrentTab] = useState("user")
    const {addAlert} = useAlert()
    const [playlists, setPlaylists] = useState([])
    const [collabPlaylists, setCollabPlaylists] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [isCPLoaded, setCPLoaded] = useState(false)
    const {getPlaylistsByUser, getCollaborativePlaylistsByUser, addSongToPlaylist} = useUser({})
    const {modalContent, toggleModal, openModal, closeModal} = useModal()
    const navigate = useNavigate()

    useEffect(() => {
        getPlaylistsByUser(currentUser?.uid, "all")
      .then((pl) => {
         if (pl && !pl?.error) {
          setPlaylists(pl)
          setLoaded(true)
        }
       
      })
      .catch((err) => {
        //console.log(err);
        setLoaded(true)
        return;
      });
        getCollaborativePlaylistsByUser(currentUser?.uid, "all").then(cPl => {
            if(cPl && !cPl.error){
                setCollabPlaylists(cPl)
                setCPLoaded(true)
            }
      }).catch((err) => {
        setCPLoaded(true)
      })
        return () => {}
    },[])

    const addToPlaylist = async (playlistId, songId) => {
      addAlert({
        title: "Add to Playlist",
        message:"Adding to playlist",
        type: ALERT_TYPES.INFO
      })
        return await addSongToPlaylist(currentUser?.uid, playlistId, songId)
          .then((data) => {
            if (data !== false) {
              modalContent(
                <OptionModal
                  heading={"Successfully Added to Playlist"}
                >
                  <Button1
                    onClick={() => {
                      modalContent();
                      toggleModal();
                    }}
                  >
                    Ok
                  </Button1>
                </OptionModal>
              );
            }
          })
          .catch((err) => {
            //console.log(err);
            modalContent(
              <OptionModal  heading={"Something went wrong"}>
                <HoverOption onClick={ () =>{
                    addToPlaylist(playlistId, songId)
                    }}>
                  Try Again
                </HoverOption>
                <Button1
                  onClick={() => {
                    modalContent();
                    toggleModal();
                  }}
                >
                  Cancel
                </Button1>
              </OptionModal>
            );
            toggleModal();
          });
      };

  return (
    <>
     <OptionModal

                heading={"Select Playlist"}
                onCancel={() => {
                  modalContent();
                  closeModal();
                }}
              >
                <NavContainer><Nav>
                      <NavLink onClick={() => setCurrentTab('user')} active={currentTab === 'user'}>My Playlists</NavLink>
                      <NavLink onClick={() => setCurrentTab('collaborate')} active={currentTab === 'collaborate'}>Collab Playlists</NavLink>
                      </Nav>
                </NavContainer>

                {currentTab === 'user' && isLoaded && <> 
                <HoverOption onClick={() => {
                    openModal()
                    modalContent(<PlaylistCreator songId={songId}/>)}
                    }>
                  Create New
                </HoverOption>
                <br/>
                {playlists?.length < 1 && "No Playlists"}
                <FormContent style={{height: '60vh'}}>
                {playlists?.length >= 1 &&
                  <PlaylistList content={playlists.playlists.filter(playlist => !playlist?.songs ||
                    playlist.songs?.findIndex(
                      (song) => song === songId || song.songId === songId
                    ) === -1)}  
                    onClick={(index) => {
                      addToPlaylist(playlists.playlists[index].playlistId, songId)
                  }}/>}</FormContent></>}
                {currentTab === 'collaborate' && isCPLoaded && <> 
                <HoverOption onClick={() => {
                    openModal()
                    modalContent(<PlaylistCreator songId={songId}/>)}
                    }>
                  Create New
                </HoverOption>
                <br/>
                {collabPlaylists?.length < 1 && "No Playlists"}
                <FormContent style={{height: '60vh'}}>{collabPlaylists?.length >= 1 &&
                  <PlaylistList content={collabPlaylists.playlists.filter(playlist => !playlist?.songs ||
                    playlist.songs?.findIndex(
                      (song) => song === songId || song.songId === songId
                    ) === -1)}  
                    onClick={(index) => {
                      addToPlaylist(collabPlaylists.playlists[index].playlistId, songId)
                  }}/>}</FormContent></>}
                   {!isLoaded && currentTab === 'user' && <ModalLoadingScreen text={'Loading Playlists'} transparent={true}/>}
                  {!isCPLoaded && currentTab === 'collaborate' && <ModalLoadingScreen text={'Loading Playlists'} transparent={true}/>}
              </OptionModal>
            
    </>
  )
}

export default PlaylistSelector
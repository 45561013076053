import  { hexToStr } from "."
import { db} from "../components/Authorization/firebase"
import {query, doc, getDoc, collection, getDocs, where, limit, orderBy, updateDoc, onSnapshot} from 'firebase/firestore'
import { PAYOUT_TYPE } from "./Enum"
import  { makeChannelPayout } from "./xrpl"
import { addPendingMusicRoyalties } from "./dbUtils/royaltyUtils"
import { getSwərlDetails } from "./swerlUtils"

export const makeMicropayment = async (url) => {
    let swərl = getSwərlDetails(url);
    let paymentChannel;
    let payout = 19;
    let userDoc;
    let userSubscription;
  
    return await getDocs(query(collection(db, "users",), where("uid", "==", swərl?.user), limit(1)))
      .then((collection) => {
        if (collection.empty) {
          return false;
        }
        userDoc = doc(db,`users/${collection.docs[0].id}`);
        userSubscription = collection.docs[0].data()?.subscription;
        return collection.docs[0].data()?.subscription;
      })
      .then(async (sub) => {
        if (sub > 0) {
          return await getDocs(query(collection(db,`subscriptions`)
            ,where("subscriber", "==", swərl?.user)
            ,orderBy("endDate", "desc")
            ,limit(1))).then((subscriptions) => {
              if (subscriptions.empty) {
                return false
              }
              let subscription = subscriptions.docs[0].data();
              let startDate = new Date(subscription.startDate)
              let endDate = new Date(subscription?.endDate);
              let currentDate = new Date();
              if (currentDate.getTime() < startDate.getTime() || currentDate.getTime() >= endDate.getTime()) {
                return false;
              } else {
                return {
                  subscription: sub,
                  ...subscription,
                };
              }
            })
            .then(async (subscription) => {
              if (userSubscription >= 31 && subscription) {
                switch (swərl?.type) {
                  case PAYOUT_TYPE.USER:
                    paymentChannel = await getDocs(query(collection(db,"users")
                      ,where("uid", "==", swərl?.typeId)
                      ,limit(1)))
                      .then((collection) => {
                        if (collection.empty) {
                          return false;
                        }
                        return collection?.docs[0]?.data()?.paymentChannel;
                      })
                      .catch((err) => {
                        console.error(err);
                        return false;
                      });
                    break;
                  case PAYOUT_TYPE.ARTIST:
                    paymentChannel = await getDoc(doc(db,`artists/${swərl?.typeId}`))
                      .then((artist) => {
                        if (artist?.exists) {
                          return artist.data()?.paymentChannel;
                        }
                        return false;
                      })
                      .catch((err) => {
                        console.error(err);
                        return false;
                      });
                    break;
                  case PAYOUT_TYPE.PODCASTER:
                    paymentChannel = await getDoc(doc(db,`podcasters/${swərl?.typeId}`))
                      .then((podcaster) => {
                        if (podcaster?.exists) {
                          return podcaster.data()?.paymentChannel;
                        }
                        return false;
                      })
                      .catch((err) => {
                        console.error(err);
                        return false;
                      });
                    break;
                  case PAYOUT_TYPE.RECORD_LABEL:
                    paymentChannel = await getDoc(doc(db,`recordLabels/${swərl?.typeId}`))
                      .then((label) => {
                        if (label?.exists) {
                          return label.data()?.paymentChannel;
                        }
                        return false;
                      })
                      .catch((err) => {
                        console.error(err);
                        return false;
                      });
                    break;
                      
                  default:
                    break;
                }
                try {
                  if(userSubscription > 5000000){
                    for (let i = 0; i < 2; i++) {
                        onSnapshot(userDoc,
                        async (docSnapshot) => {
                          userSubscription = docSnapshot.data()?.subscription;
                        },
                        (err) => {
                          console.error(`Encountered error: ${err}`);
                          return false
                        }
                      );
                      if (userSubscription >= 12) {
                        if (userSubscription > 10000000) {
                          payout = 1860;
                        }
                        if (
                          userSubscription <= 10000000 &&
                          userSubscription > 8000000
                        ) {
                          payout = 930;
                        }
                        if (
                          userSubscription <= 8000000 &&
                          userSubscription > 5000000
                        ) {
                          payout = 570;
                        }
                        if (
                          userSubscription <= 5000000 &&
                          userSubscription > 2000000
                        ) {
                          payout = 192;
                        }
                        if (userSubscription <= 2000000 && userSubscription > 500000) {
                          payout = 19;
                        }
                        if (userSubscription <= 500000) {
                          payout = 6;
                        }
                        await processPayout(payout, swərl, paymentChannel?.channel, userDoc)
                        
                      }
                    }
                  }else{
                    
                      await onSnapshot(userDoc,
                        async (docSnapshot) => {
                          userSubscription = docSnapshot.data()?.subscription;
                        },
                        (err) => {
                          console.error(`Encountered error: ${err}`);
                          return false
                        }
                      );
                      if (userSubscription >= 12) {
                         if (userSubscription > 10000000) {
                          payout = 1860;
                        }
                        if (
                          userSubscription <= 10000000 &&
                          userSubscription > 8000000
                        ) {
                          payout = 930;
                        }
                        if (
                          userSubscription <= 8000000 &&
                          userSubscription > 5000000
                        ) {
                          payout = 570;
                        }
                        if (
                          userSubscription <= 5000000 &&
                          userSubscription > 2000000
                        ) {
                          payout = 192;
                        }
                        if (userSubscription <= 2000000 && userSubscription > 500000) {
                          payout = 19;
                        }
                        if (userSubscription <= 500000){
                          payout = 6;
                        }
                        await processPayout(payout, swərl, paymentChannel?.channel, userDoc)
                        
                       
                      }
                  }
                  return true
                } catch (err) {
                  //console.error(err);
                  return false
                }
              } else {
                return false
              }
            })
            .catch((err) => {
              //console.error(err);
              return false
            });
        } else {
          return false
        }
      })
      .catch((err) => {
        console.error(err);
        return false
      });
  };

  export const processPayout = async(payout, swərl, paymentChannel, userDoc) => {
    let payment
    if(swərl.type === PAYOUT_TYPE.ROYALTY_SHARE){
      payment = await addPendingMusicRoyalties(swərl.typeId, payout.toString())
    }else{
      /*  if (swərl.paymentChannel?.channel) {
      payment = await makeChannelPayout({
        paymentChannel: paymentChannel,
        amount: payout.toString(),
        title: swərl.title
      });
      if(!payment){
        payment = await makePendingFundsPayment(
          swərl.type,
          swərl.typeId,
          payout
        );
      }
    } else {
      payment = await makePendingFundsPayment(
        swərl.type,
        swərl.typeId,
        payout
      );
    } */
    payment = await makePendingFundsPayment(
      swərl.type,
      swərl.typeId,
      payout
    );
    }
    if (payment) {
     
     return await getDoc(userDoc).then(
        async (docSnapshot) => {
          if(parseInt(docSnapshot.data()?.subscription) > 0){
            let fee = 12

            if(payout === 192){
              fee = 9
            }
            if(payout === 19 ){
              fee = 6
            }
            if(payout === 6){
              fee = 3
            }
            
            await updateDoc(userDoc,{
              subscription: parseInt(parseInt(docSnapshot.data()?.subscription) - parseInt(payout + fee)),
            });
            return true
          }
          return true
        }).catch(
        (err) => {
          console.error(`Encountered error: ${err}`);
          return false
        }
      );
      
      
    }
  }

  export const makePendingFundsPayment = async (type, id, payout) => {
    let payment;
    switch (type) {
      case PAYOUT_TYPE.USER:
        return await getDocs(query(collection(db,"users")
          ,where("uid", "==", id)
          ,limit(1)))
          .then(async (collection) => {
            if (collection.empty) {
              return false;
            }
            let pendingFunds = 0
            await onSnapshot(doc(db,`users/${collection?.docs[0]?.id}`),
              async (docSnapshot) => {
                  pendingFunds = parseInt(docSnapshot.data()?.pendingFunds) > 0 ? parseInt(docSnapshot.data()?.pendingFunds) : 0;
                  return pendingFunds
              },
              (err) => {
                console.error(`Encountered error: ${err}`);
                return false
              }
            );
            pendingFunds = parseInt(pendingFunds) + payout
              payment = await updateDoc(doc(db,`users/${collection?.docs[0]?.id}`),{ pendingFunds: pendingFunds })
                .then(() => {
                  return true;
                })
                .catch((err) => {
                  console.error(err);
                  return false;
                });
            return payment;
          })
          .catch((err) => {
            console.error(`Encountered error: ${err}`);

            return false;
          });
     
      case PAYOUT_TYPE.ARTIST:
        return await getDoc(doc(db,`artists/${id}`))
          .then(async (artist) => {
            if (artist?.exists) {
              let pendingFunds = artist.data()?.pendingFunds ? artist.data()?.pendingFunds : 0;
                await onSnapshot(doc(db,`artists/${id}`),
                async (docSnapshot) => {
                  pendingFunds = parseInt(docSnapshot.data()?.pendingFunds) > 0 ? parseInt(docSnapshot.data()?.pendingFunds) : 0;
                  return pendingFunds
                },
                (err) => {
                  console.error(`Encountered error: ${err}`);
                  return false
                }
              );
              pendingFunds = parseInt(pendingFunds) + payout
                payment = await updateDoc(doc(db,`artists/${id}`),{ pendingFunds: pendingFunds })
                  .then(() => {
                    return true;
                  })
                  .catch((err) => {
                    //console.error(`Encountered error: ${err}`);
                    return false;
                  });
              return payment;
            }
            return false;
          })
          .catch((err) => {
            console.error(err);
            payment = false;
            return false;
          });
        
      case PAYOUT_TYPE.PODCASTER:
        return await getDoc(doc(db,`podcasters/${id}`))
          .then(async (podcaster) => {
            if (podcaster?.exists) {
                let pendingFunds = 0
                await onSnapshot(doc(db,`podcasters/${id}`),
                async (docSnapshot) => {
                  pendingFunds = parseInt(docSnapshot.data()?.pendingFunds) > 0 ? parseInt(docSnapshot.data()?.pendingFunds) : 0;
                  return pendingFunds
                },
                (err) => {
                  console.error(err);                  
                  return false
                }
              );
              pendingFunds = parseInt(pendingFunds) + payout
                payment = await updateDoc(doc(db,`podcasters/${id}`),{ pendingFunds: pendingFunds })
                  .then(() => {
                    return true;
                  })
                  .catch((err) => {
                    console.error(err);
                    return false;
                  });

              return payment;
            }
            payment = false;
            return false;
          })
          .catch((err) => {
            console.error(err);
            payment = false;
  
            return false;
          });
        
      case PAYOUT_TYPE.RECORD_LABEL:
        return await getDoc(doc(db,`recordLabels/${id}`))
          .then(async (label) => {
            if (label?.exists) {
              let pendingFunds = 0
              await onSnapshot(doc(db,`recordLabels/${id}`),
              async (docSnapshot) => {
                  pendingFunds = parseInt(docSnapshot.data()?.pendingFunds) > 0 ? parseInt(docSnapshot.data()?.pendingFunds) : 0;
                  return pendingFunds
              },
              (err) => {
                console.error(`Encountered error: ${err}`);
                return false
              }
            );
            pendingFunds = parseInt(pendingFunds) + payout
                payment = await updateDoc(doc(db,`recordLabels/${id}`),{ pendingFunds: pendingFunds })
                  .then(() => {
                    return true;
                  })
                  .catch((err) => {
                    console.error(err);
                    return false;
                  });
              return payment;
            }
            return false;
          })
          .catch((err) => {
            console.error(err);
            payment = false;
            return false;
          });
       
  
      default:
        payment = false;
  
        return false;
        break;
    }
  };


  export const addToPendingFunds = (type, id, payout) => {

  }


import React, { useEffect, useState } from "react";
import {
  TimelineControler,
  Video,
  VideoControlsButton,
  VideoControlsContainer,
  VideoDurationContainer,
  VideoPlayBackSpeedContainer,
  VideoPlayerContainer,
  VideoPlayerControls,
  VideoPlayerLogo,
  //VideoPlayerControlsWrapper,
  VideoPlayerSlider,
  VideoPlayerVolumeContainer,
  VideoPlayerVolumeSlider,
} from "./VideoPlayerElements";
//import { useAudio } from "../AudioPlayer/context/AudioContext";
//import ImageUtils, { VideoUtils } from "../../utils/ImageUtils";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaVolumeDown,
  FaRegSquare,
  FaCog,
} from "react-icons/fa";
//import { PiPictureInPictureFill } from "react-icons/pi";
import {
  MdFullscreen,
  MdFullscreenExit,
  MdOutlinePanoramaWideAngle,
  MdOutlineWidthWide,
  MdWidthWide,
  MdOutlineSmartScreen,
} from "react-icons/md";
import { TbRectangle } from "react-icons/tb";
import useVideoPlayer from "./VideoPlayerContext";
import { ALERT_TYPES, MUSIC_PLAYING_FROM, VIDEO_SCREEN_SIZE } from "../../utils/Enum";
import { useAlert } from "../Alert/AlertContext";
import { useAuth } from "../../contexts/AuthContext";
import { useModal } from "../Modal/ModalContext";
import StreamingSubscriptionModal from "../StreamingSubscriptionModal";
import OptionModal from "../OptionModal";
import { HoverOption } from "../MenuOptions/MenuOptionsElements";
import logo from "../../images/sonar_muse_logo_nav1.png";
import invertedLogo from "../../images/sonar_muse_logo_nav_invert1.png";
import logo2 from "../../images/sonar_muse_logo_nav2.png";
import invertedLogo2 from "../../images/sonar_muse_logo_nav_invert2.png";
import { useAudio } from "../AudioPlayer/context/AudioContext";
import { useWebsiteTheme } from "../WebsiteTheme/WebsiteThemeContext";
import ImageUtils from "../../utils/ImageUtils";
import { getVideoResolutions } from "../../utils/dbUtils/videoUtils";
import { convertDuration } from "../../utils";

const VideoPlayer = ({
  stopMainPlayer,
  isPlaying,
  content,
  validateSubscription,
}) => {
  const { currentUser, isUserSubscribed, getUserSubscription } = useAuth();
  const { addAlert } = useAlert();
  const { defaultAlbumArt } = ImageUtils();
  const { themeColors, theme } = useWebsiteTheme();
  const { modalContent, toggleModal, isOpen, openModal, closeModal } =
    useModal();
  const [resolutions, setResolutions] = useState(
    content?.videoResolutions?.length > 0
      ? getVideoResolutions(content?.url, content?.videoResolutions)
      : []
  );
  const [currentResolution, setCurrentResolution] = useState(
    (content?.videoResolutions?.length > 0 &&
      content?.videoResolutions.filter((vidRes) => vidRes?.resolution === 480)
        .length > 0)
      ? content?.videoResolutions.filter(
          (vidRes) => vidRes?.resolution === 480
        )[0]?.url
      : content?.video?.url
  );
  const {
    videoPlayer,
    progressBar,
    videoContainer,
    togglePlay,
    toggleMute,
    stop,
    toggleScreenMode,
    toggleVolume,
    togglePlaybackSpeed,
    calcTime,
    handleEnd,
    changeRange,
    timeTravel,
    play,
    isVideoPlaying,
    isMute,
    currentTime,
    duration,
    volume,
    playback,
    isFullScreen,
    isTheatreMode,
    setCurrentVideo,
    setPlaylist,
    //isPictureInPicture,
  } = useVideoPlayer();
  //const { video, video1, video2, video3 } = VideoUtils();

  useEffect(() => {
    let res =
      content?.videoResolutions &&
      content?.videoResolutions.filter((vidRes) => vidRes?.resolution === 720)
        ?.length > 0
        ? content?.videoResolutions.filter(
            (vidRes) => vidRes?.resolution === 720
          )[0]?.url
        : content?.url;
    setPlaylist([{...content,
      playingFrom: {
         playingFrom: MUSIC_PLAYING_FROM.VIDEO,
        id: content?.videoId,
      }
    }]);
    setCurrentResolution(res);
    return () => {};
  }, [content]);

  useEffect(() => {
    if (isPlaying && isVideoPlaying) {
      stop();
      timeTravel(0);
    }
    return () => {};
  }, [isPlaying]);

  useEffect(() => {
    if(videoPlayer?.current){
      videoPlayer.current.currentTime = currentTime
      if(videoPlayer?.current?.paused && currentTime > 0){
        play()
        
      }
    }
  
    return () => {}
  }, [currentResolution])

  const playVideo = async () => {
    if (validateSubscription) {
      if (currentUser?.uid) {
        if (await isUserSubscribed()) {
          setPlaylist([{...content,
            playingFrom: {
               playingFrom: MUSIC_PLAYING_FROM.VIDEO,
              id: content?.videoId,
            }
          }])
          togglePlay();
          stopMainPlayer();
          return;
        } else {
          if (isVideoPlaying) {
            togglePlay();
          }
          openModal();
          return modalContent(
            <>
              <StreamingSubscriptionModal
                onSuccess={() => {
                  getUserSubscription(currentUser?.uid);
                  togglePlay();
                  modalContent(
                    <OptionModal heading={"Subscription Unsucessful"}>
                      <HoverOption onClick={closeModal()}>OK</HoverOption>
                    </OptionModal>
                  );
                }}
                onFail={() => {
                  modalContent(
                    <OptionModal heading={"Subscription Unsucessful"}>
                      <HoverOption
                        onClick={() => {
                          closeModal();
                          modalContent();
                        }}
                      >
                        OK
                      </HoverOption>
                    </OptionModal>
                  );
                }}
                cancel={() => {
                  closeModal();
                  modalContent();
                }}
              />
            </>
          );
        }
      } else {
        addAlert({
          title: "Not Logged In",
          type: ALERT_TYPES.WARNING,
          message: "You need to log in to play this video",
        });
        return;
      }
    } else {
      togglePlay();
      stopMainPlayer();
      return;
    }
  };

  return (
    <>
      <VideoPlayerContainer
        className="video-container"
        id="video-container"
        ref={videoContainer}
        theatreMode={isTheatreMode}
        fullScreen={isFullScreen}
        onDoubleClick={() => {
          playVideo();
        }}
      >
        <VideoControlsContainer paused={!isVideoPlaying}>
          <TimelineControler>
            <VideoPlayerSlider
              progressWidth={`${(currentTime / duration) * 100}%`}
              bufferWidth={`${
                (progressBar?.current?.buffered?.end(
                  progressBar?.current?.buffered.length - 1
                ) /
                  duration) *
                100
              }%`}
              value={currentTime}
              max={duration}
              min={0}
              type={"range"}
              ref={progressBar}
              onChange={changeRange}
            />
          </TimelineControler>
          <VideoPlayerControls>
            <VideoPlayerLogo>
              <img
                src={theme === "dark" ? logo2 : invertedLogo2}
                alt={"Sonar Muse"}
              />
            </VideoPlayerLogo>
            <VideoControlsButton
              onClick={() => {
                playVideo();
              }}
            >
              {isVideoPlaying && <FaPause />} {!isVideoPlaying && <FaPlay />}
            </VideoControlsButton>
            <VideoPlayerVolumeContainer>
              <VideoControlsButton
                onClick={() => {
                  toggleMute();
                }}
              >
                {isMute ? (
                  <FaVolumeMute />
                ) : volume > 0.5 ? (
                  <FaVolumeUp />
                ) : (
                  volume <= 0.5 && <FaVolumeDown />
                )}
              </VideoControlsButton>
              <VideoPlayerVolumeSlider
                type="range"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={toggleVolume}
              />
            </VideoPlayerVolumeContainer>

            <VideoDurationContainer>
              {calcTime(currentTime)}/{convertDuration(duration)}
            </VideoDurationContainer>
            {content?.videoResolutions?.length > 0 && (
              <VideoPlayBackSpeedContainer
                onClick={() => {
                  if(isFullScreen){
                    toggleScreenMode(VIDEO_SCREEN_SIZE.FULL_SCREEN)
                  }
                  openModal();
                  modalContent(
                    <VideoPlayerOptions
                      currentResolution={currentResolution}
                      setCurrentResolution={setCurrentResolution}
                      videoResolutions={resolutions}
                      videoPlayer = {videoPlayer}
                      toggleScreenMode={toggleScreenMode}
                      screenMode={isFullScreen && VIDEO_SCREEN_SIZE.FULL_SCREEN }
                      playback={playback}
                      play={play}
                      currentTime={currentTime}
                      setCurrentTime={timeTravel}
                      togglePlaybackSpeed={togglePlaybackSpeed}
                    />
                  );
                }}
              >
                <FaCog />
              </VideoPlayBackSpeedContainer>
            )}
            {(content?.videoResolutions?.length === 0 ||
              !content?.videoResolutions) && (
              <VideoPlayBackSpeedContainer
                onClick={() => {
                  togglePlaybackSpeed();
                }}
              >
                {playback}x
              </VideoPlayBackSpeedContainer>
            )}
            {/* <VideoControlsButton
              onClick={() => toggleScreenMode(VIDEO_SCREEN_SIZE.PIP)}
            >
              <PiPictureInPictureFill /> 
            </VideoControlsButton>*/}
            {
              <VideoControlsButton
                onClick={() => toggleScreenMode(VIDEO_SCREEN_SIZE.THEATRE)}
              >
                {isTheatreMode ? <TbRectangle /> : <MdOutlineSmartScreen />}
              </VideoControlsButton>
            }
            <VideoControlsButton
              onClick={() => toggleScreenMode(VIDEO_SCREEN_SIZE.FULL_SCREEN)}
            >
              {isFullScreen ? <MdFullscreenExit /> : <MdFullscreen />}
            </VideoControlsButton>
          </VideoPlayerControls>
        </VideoControlsContainer>
        <Video
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          className="video"
          id="video"
          src={currentResolution}
          muted={isMute}
          loop={false}
          ref={videoPlayer}
          preload={"auto"}
          poster={
            content?.albumArt
              ? content?.albumArt
              : content?.thumbnail
              ? content?.thumbnail
              : defaultAlbumArt
          }
          onEnded={handleEnd}
        />
      </VideoPlayerContainer>
    </>
  );
};

const VideoPlayerOptions = ({
  currentTime,
  setCurrentTime,
  videoPlayer,
  play,
  toggleScreenMode,
  screenMode,
  currentResolution,
  setCurrentResolution,
  videoResolutions,
  togglePlaybackSpeed,
  playback,
}) => {
  const { modalContent, closeModal, toggleModal } = useModal();

  const changeResolution = (quality) => {
    setCurrentResolution(
      videoResolutions.filter((r) => r?.resolution === quality)[0]?.url
    );
    if(screenMode === VIDEO_SCREEN_SIZE.FULL_SCREEN){
      toggleScreenMode(VIDEO_SCREEN_SIZE.FULL_SCREEN)
    }
    closeModal();
    
  };

  const displayResolutionSelector = () => {
    modalContent(
      <OptionModal heading={"Select Video Quality"}>
        {videoResolutions?.map((r, index) => {
          return (
            <HoverOption
              onClick={() => {
                if (currentResolution === r?.url) {
                  closeModal();
                  modalContent();
                  if(screenMode === VIDEO_SCREEN_SIZE.FULL_SCREEN){
                    toggleScreenMode(VIDEO_SCREEN_SIZE.FULL_SCREEN)
                  }
                } else {
                  changeResolution(r?.resolution);
                }
              }}
            >
              {r?.resolution}p {currentResolution === r?.url && `(current)`}
            </HoverOption>
          );
        })}
        <HoverOption
          onClick={() => {
            if(screenMode === VIDEO_SCREEN_SIZE.FULL_SCREEN){
              toggleScreenMode(VIDEO_SCREEN_SIZE.FULL_SCREEN)
            }
            closeModal();
            modalContent();
            
          }}
        >
          Cancel
        </HoverOption>
      </OptionModal>
    );
  };

  return (
    <>
      <OptionModal heading={"Video Options"}>
        <HoverOption onClick={displayResolutionSelector}>
          Video Quality (
          {
            videoResolutions.filter((r) => r?.url === currentResolution)[0]
              .resolution
          }
          p){" "}
        </HoverOption>
        <HoverOption
          onClick={() => {
            togglePlaybackSpeed();
            closeModal();
            modalContent();
          }}
        >
          Playback Speed ({playback}x)
        </HoverOption>
        <HoverOption onClick={() => {
            closeModal();
            modalContent();
          }}>Cancel</HoverOption>
      </OptionModal>
    </>
  );
};

export default VideoPlayer;
